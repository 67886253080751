import React from "react";
import { connect } from "react-redux";

import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";
import {
  Avatar,
  Button,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import color from "@mui/material/colors/yellow";

import { formatPhoneNumberIntoE164 } from "../../common/utils";
import isEmail from "validator/lib/isEmail";

import TagManager from "react-gtm-module";
import ReactPlayer from "react-player";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "../../theme/react-phone-input-2.css";

import klevrTestimonialF from "../../common/avatars/klevrTestimonialF.jpg";
import klevrTestimonialM from "../../common/avatars/klevrTestimonialM.jpg";
import MortgageRates from "../../common/background-images/Mortgage-Rates.jpg";
import { colors } from "../../common/colors";
import Loading from "../../common/loading";
import klevrImage from "../../common/logos/klevr-logo-fox-only.svg";
import ReviewCard from "../../common/review";
import {
  createLeadBuyer,
  updateForm,
  updateLeadBuyer,
  updateStep,
} from "../../redux/buyer-actions";
import LandingPagesFooter from "../common/landing-pages-footer";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(),
      backgroundColor: "white",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "0px",
        paddingTop: theme.spacing(2),
      },
    },
    section1: {
      margin: theme.spacing(3),
    },
    dividerRoot: {
      backgroundColor: colors.black,
      margin: `${theme.spacing(2)} 0px ${theme.spacing(4)}`,
    },
    divider2Root: {
      backgroundColor: colors.black,
      margin: `${theme.spacing(2)} 0px`,
    },
    userInfo: {
      color: colors.black,
    },
    leftDivImage: {
      marginTop: "5px",
      height: "300px",
      width: "100%",
      paddingTop: "10px",
      backgroundImage: `url(${MortgageRates})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      [theme.breakpoints.down("sm")]: {
        height: "200px",
        marginBottom: theme.spacing(2),
        paddingTop: "0px",
      },
    },
    inputContainer: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
      },
    },
    userlogo: {
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        height: "75%",
      },
    },
    mainHeader: {
      color: colors.red,
    },
    subHeader: {
      color: colors.black,
    },
    listItemGutter: {
      padding: `0px ${theme.spacing()} 0px 0px`,
    },
    userAvatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
    input: {
      // marginTop: `${theme.spacing(3)}px !important`,
      backgroundColor: colors.lightGrey,
      borderRadius: theme.spacing(0.5),
      padding: "8px",
      "&:focus": {
        backgroundColor: colors.lightGrey,
        borderRadius: theme.spacing(0.5),
      },
    },
    selectLabel: {
      color: "black !important",
      fontSize: theme.typography.subtitle1.fontSize,
    },
    containedButton: {
      backgroundColor: colors.klevrGreen,
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: colors.klevrGreenDark,
      },
    },
    playerWrapper: {
      position: "relative",
      paddingTop: "56.25%",
    },
    reactPlayer: {
      position: "absolute",
      top: "0",
      left: "0",
    },
  });

interface Props extends WithStyles<typeof styles> {
  //
}

class LandingStep1 extends React.Component<any> {
  override componentDidMount() {
    const { form, landing, updateForm, isDemo } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      fromLandingPageId: landing.id,
      tags: ["buyer"],
      email: form.email || "",
      numberBedrooms: "",
      numberBathrooms: "",
      timeframe: "",
      name: "",
      phoneNumber: "",
    };
    updateForm(newForm);

    if (isDemo === true) {
      return;
    } else {
      const tagManagerArgs = {
        gtmId: "GTM-N5HCWHV",
        events: {
          event: "initialPageView",
        },
        dataLayer: {
          initialPageView: true,
          userId: landing.userId,
          landingPageId: landing.id,
          tags: landing.settings.templateType,
        },
        dataLayerName: "KlevrLandingPages",
      };

      TagManager.initialize(tagManagerArgs);
    }
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    updateForm(newForm);
    console.log("newForm", newForm);
  };

  setPhoneNumber = (value: any, country: any, e: any, formattedValue: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      phoneNumber: "+" + value,
    };

    updateForm(newForm);
    console.log("newForm", newForm);
  };

  handleClick = (e: any) => {
    const { form, isDemo, updateStep, createLeadBuyer, updateLeadBuyer } =
      this.props;

    console.log(form);

    if (isDemo) {
      updateStep("success");
      return;
    }

    let phoneNumber = formatPhoneNumberIntoE164(form.phoneNumber);

    if (phoneNumber && form.name.length > 0) {
      let newForm = {
        ...form,
        phoneNumber,
      };

      if (isEmail(form.email)) {
        if (!form.id) {
          createLeadBuyer(newForm).then(() => {
            updateStep("success");
          });
        } else {
          updateLeadBuyer(form).then(() => {
            updateStep("success");
          });
        }
      }
    }
  };

  override render() {
    const {
      classes,
      form,
      demoForm,
      landing,
      landingUser,
      isFetching,
      isDemo,
      settings,
    } = this.props;

    const customColors = {
      customHeadingColor: {
        color:
          isDemo || !settings.headerTextColor.rgba
            ? "#000000"
            : settings.headerTextColor.rgba,
      },
      customSubHeaderColor: {
        color:
          isDemo || !settings.subHeaderTextColor.rgba
            ? "#000000"
            : settings.subHeaderTextColor.rgba,
      },
      customButtonColor: {
        color:
          isDemo || !settings.buttonColor.rgba
            ? "#00D88E"
            : settings.buttonColor.rgba,
      },
      customButtonTextColor: {
        color:
          isDemo || !settings.buttonTextColor.rgba
            ? "#FFFFFF"
            : settings.buttonTextColor.rgba,
      },
    };

    return (
      <div className={classes.root}>
        <Container maxWidth="md">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <List>
                <ListItem
                  classes={{
                    gutters: classes.listItemGutter,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={
                        isDemo
                          ? "KlevrLeads"
                          : `${landingUser.firstName} ${landingUser.lastName}`
                      }
                      src={
                        isDemo ? klevrTestimonialF : landingUser.profileImageUrl
                      }
                      className={classes.userAvatar}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.userInfo}
                    primary={
                      isDemo
                        ? "Klevr Leads"
                        : `${landingUser.firstName} ${landingUser.lastName}`
                    }
                    secondary={
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="caption">
                          {isDemo
                            ? "Klevr Realty"
                            : `${landingUser.companyName}`}
                        </Typography>
                        <Typography variant="caption">
                          {isDemo
                            ? "License: Demo"
                            : `License: ${settings.userLicense}`}
                        </Typography>
                      </div>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <div
                style={{
                  height: "56px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  className={classes.userlogo}
                  src={isDemo ? klevrImage : landingUser.logoImageUrl}
                />
              </div>
            </Grid>
          </Grid>
          <div className={classes.section1}></div>
          <Typography
            variant="h3"
            gutterBottom
            style={{ color: customColors.customHeadingColor.color }}
          >
            {landing && landing.settings && landing.settings.heading
              ? landing.settings.heading
              : "Customizable Video Landing Page Heading"}
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            style={{ color: customColors.customSubHeaderColor.color }}
          >
            {landing && landing.settings && landing.settings.subheading
              ? landing.settings.subheading
              : "Customizable Sub Heading"}
          </Typography>
          <Divider classes={{ root: classes.divider2Root }} />

          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={9}>
              <div className={classes.playerWrapper}>
                <ReactPlayer
                  className={classes.reactPlayer}
                  url={
                    isDemo
                      ? "https://www.youtube.com/watch?v=NpEaa2P7qZI"
                      : landing.settings.videoUrl
                  }
                  controls={
                    isDemo || landing.settings.videoHostCompany === "Vimeo"
                      ? true
                      : false
                  }
                  light={isDemo ? true : false}
                  height="100%"
                  width="100%"
                />
              </div>
            </Grid>
            {isDemo && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.subHeader}
                >
                  Amazing Call To Action!
                </Typography>
              </Grid>
            )}

            {landing && landing.settings && landing.settings.callToAction && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.subHeader}
                >
                  {landing.settings.callToAction}
                </Typography>
              </Grid>
            )}
            {/* <Grid item xs={12} sm={6}>
              <div className={classes.leftDivImage} />
            </Grid> */}
            <Grid
              item
              xs={12}
              md={9}
              spacing={2}
              container
              justifyContent="center"
              alignItems="center"
              className={classes.inputContainer}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="primary">
                    Name
                  </Typography>
                  <Input
                    placeholder="Your name"
                    className={classes.input}
                    name="name"
                    id="name"
                    disableUnderline
                    disabled={isDemo}
                    value={isDemo ? demoForm.name : form.name || ""}
                    onChange={this.handleChange}
                  />
                  {/* {errors.name && (
                    <FormHelperText>{errors.name}</FormHelperText>
                  )} */}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" align="left" color="primary">
                  Phone
                </Typography>
                <PhoneInput
                  inputProps={{
                    name: "phoneNumber",
                    label: "phoneNumber",
                    // required: true,
                  }}
                  inputStyle={{
                    background: "#eeeeee",
                    width: "100%",
                    borderRadius: "4px",
                    height: "48px",
                  }}
                  // specialLabel={"Phone Number"}
                  country={"us"} // initialcountry (in some cases behaves weirdly)
                  onlyCountries={["au", "ca", "it", "mx", "us", "es"]}
                  countryCodeEditable={false}
                  disableDropdown={true}
                  placeholder={""}
                  disabled={isDemo}
                  value={isDemo ? demoForm.phoneNumber : form.phoneNumber || ""}
                  onChange={(value, country, e, formattedValue) =>
                    this.setPhoneNumber(value, country, e, formattedValue)
                  }
                />
                {/* {!!errors.phoneNumber && (
                  <FormHelperText error={true}>
                    {errors.phoneNumber}
                  </FormHelperText>
                )} */}
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="primary">
                    Email
                  </Typography>
                  <Input
                    placeholder="Your email address"
                    className={classes.input}
                    name="email"
                    id="email"
                    disableUnderline
                    value={isDemo ? demoForm.email : form.email || ""}
                    onChange={this.handleChange}
                  />
                  {/* {errors.email && (
                    <FormHelperText>{errors.email}</FormHelperText>
                  )} */}
                </FormControl>
              </Grid>
              {(isDemo || !landing.settings.buttonText) && (
                <Grid item xs={12}>
                  <Button
                    // className={classes.containedButton}
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    disabled={false}
                    onClick={this.handleClick}
                  >
                    Contact Me
                  </Button>
                </Grid>
              )}
              {landing && landing.settings && landing.settings.buttonText && (
                <Grid item xs={12}>
                  <Button
                    // className={classes.containedButton}
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    disabled={false}
                    onClick={this.handleClick}
                    style={{
                      color: customColors.customButtonTextColor.color,
                      backgroundColor: customColors.customButtonColor.color,
                    }}
                  >
                    {landing.settings.buttonText
                      ? landing.settings.buttonText
                      : "Contact Me"}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Divider classes={{ root: classes.divider2Root }} />
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              wrap="wrap"
              spacing={4}
            >
              {isDemo && (
                <Grid item xs={12} sm>
                  <Typography
                    variant="h6"
                    style={{ color: "black" }}
                    gutterBottom
                  >
                    Info Block Header 1
                  </Typography>
                  <Typography color="textPrimary">
                    This is text for Info Block Paragraph 1
                  </Typography>
                </Grid>
              )}

              {landing &&
                landing.settings &&
                landing.settings.infoBlockHeader1 && (
                  <Grid item xs={12} sm>
                    <Typography
                      variant="h6"
                      style={{ color: "black" }}
                      gutterBottom
                    >
                      {landing.settings.infoBlockHeader1}
                    </Typography>
                    <Typography color="textPrimary">
                      {landing.settings.infoBlockParagraph1}
                    </Typography>
                  </Grid>
                )}

              {isDemo && (
                <Grid item xs={12} sm>
                  <Typography
                    variant="h6"
                    style={{ color: "black" }}
                    gutterBottom
                  >
                    Info Block Header 2
                  </Typography>
                  <Typography color="textPrimary">
                    This is text for Info Block Paragraph 2
                  </Typography>
                </Grid>
              )}

              {landing &&
                landing.settings &&
                landing.settings.infoBlockHeader2 && (
                  <Grid item xs={12} sm>
                    <Typography
                      variant="h6"
                      style={{ color: "black" }}
                      gutterBottom
                    >
                      {landing.settings.infoBlockHeader2}
                    </Typography>
                    <Typography color="textPrimary">
                      {landing.settings.infoBlockParagraph2}
                    </Typography>
                  </Grid>
                )}
              {isDemo && (
                <Grid item xs={12} sm>
                  <Typography
                    variant="h6"
                    style={{ color: "black" }}
                    gutterBottom
                  >
                    Info Block Header 3
                  </Typography>
                  <Typography color="textPrimary">
                    This is text for Info Block Paragraph 3
                  </Typography>
                </Grid>
              )}

              {landing &&
                landing.settings &&
                landing.settings.infoBlockHeader3 && (
                  <Grid item xs={12} sm>
                    <Typography
                      variant="h6"
                      style={{ color: "black" }}
                      gutterBottom
                    >
                      {landing.settings.infoBlockHeader3}
                    </Typography>
                    <Typography color="textPrimary">
                      {landing.settings.infoBlockParagraph3}
                    </Typography>
                  </Grid>
                )}
            </Grid>
            <Grid item>
              <LandingPagesFooter />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.buyer.form,
  demoForm: state.buyer.demoForm,
  step: state.buyer.step,
  isFetching: state.buyer.isFetching,
  landing: state.landings.landing,
  settings: state.landings.landing.settings,
  landingUser: state.landings.landingUser,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, {
  updateForm,
  updateStep,
  createLeadBuyer,
  updateLeadBuyer,
})(withStyles(styles, { withTheme: true })(LandingStep1));
