import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import SellingInInput from "../../common/page-components/selling-in-input";
import { colors } from "../../common/colors";

const styles = (theme: Theme) =>
  createStyles({
    selectLabel: {
      color: colors.darkBlue,
      // fontSize: "24px",
    },
    selectInput: {
      // marginTop: `${theme.spacing(3)} !important`,
    },
    select: {
      backgroundColor: colors.lightGrey,
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      "&:focus": {
        backgroundColor: colors.lightGrey,
        borderRadius: theme.spacing(0.5),
      },
    },
  });

export default withStyles(styles)(SellingInInput);
