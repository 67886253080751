import React from "react";
import { Theme, createStyles } from "@mui/material";
import { withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import grey from "@mui/material/colors/grey";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

const Loading = (props: any) => {
  const { classes } = props;
  return (
    <CircularProgress
      size="2rem"
      style={{
        padding: "5px",
        marginRight: "25px",
        color: grey[50],
      }}
    />
  );
};

export default withStyles(styles)(Loading);
