import React from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { Typography, Grid, Button, Input } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HouseIcon from "@mui/icons-material/House";
import TagManager from "react-gtm-module";

import ReviewCard from "../../common/review";
import {
  updateForm,
  updateStep,
  createLeadBuyer,
  updateLeadBuyer,
} from "../../redux/buyer-actions";
import isEmail from "validator/lib/isEmail";
import Loading from "../../common/loading";
import klevrTestimonialF from "../../common/avatars/klevrTestimonialF.jpg";
import klevrTestimonialM from "../../common/avatars/klevrTestimonialM.jpg";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(2),
    },
    headerFont: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
    },
    primaryText: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "15vh",
      fontSize: "3rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
        marginTop: "10vh",
      },
    },
    secondaryText: {
      color: "white",
      // opacity: 0.5,
      marginTop: theme.spacing(4),
      textAlign: "center",
    },
    actionbar: {
      height: 60,
      marginTop: theme.spacing(4),
      marginBottom: "10vh",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "15vh",
      },
    },
    addressInput: {
      height: 60,
      backgroundColor: "white",
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    searchButton: {
      flexBasis: "auto",
    },
    action: {
      height: "100%",
      minWidth: "40px",
      alignItems: "center",
      backgroundColor: "white",
      fontWeight: "bold",
      color: "#2694f1",
      textDecoration: "none",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      "&:hover": {
        backgroundColor: "#2694f1",
        color: "white",
      },
    },
    descriptionText: {
      color: "white",
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    spacer: {
      flex: 1,
    },
    reviewSpacer: {
      [theme.breakpoints.up("md")]: {
        marginTop: "80px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10vh",
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  //
}

class LandingStep1 extends React.Component<any> {
  override componentDidMount() {
    const { form, landing, updateForm, isDemo } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      fromLandingPageId: landing.id,
      tags: ["buyer"],
      email: form.email || "",
      numberBedrooms: "",
      numberBathrooms: "",
      timeframe: "",
      name: "",
      phoneNumber: "",
    };
    updateForm(newForm);

    if (isDemo === true) {
      return;
    } else {
      const tagManagerArgs = {
        gtmId: "GTM-N5HCWHV",
        events: {
          event: "initialPageView",
        },
        dataLayer: {
          initialPageView: true,
          userId: landing.userId,
          landingPageId: landing.id,
          tags: landing.settings.templateType,
        },
        dataLayerName: "KlevrLandingPages",
      };

      TagManager.initialize(tagManagerArgs);
    }
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    updateForm(newForm);
  };

  handleClick = (e: any) => {
    const { form, isDemo, updateStep, createLeadBuyer, updateLeadBuyer } =
      this.props;

    if (isDemo) {
      updateStep("2");
      return;
    }

    if (isEmail(form.email)) {
      if (!form.id) {
        createLeadBuyer(form).then(() => {
          updateStep("2");
        });
      } else {
        updateLeadBuyer(form).then(() => {
          updateStep("2");
        });
      }
    }
  };

  override render() {
    const { classes, form, demoForm, landing, isFetching, isDemo } = this.props;

    return (
      <div className={classes.root}>
        <Typography className={classes.primaryText}>
          {landing && landing.settings && landing.settings.heading
            ? landing.settings.heading
            : "Reimagine Home"}
        </Typography>
        <Typography variant="h6" className={classes.secondaryText}>
          {landing && landing.settings && landing.settings.subheading
            ? landing.settings.subheading
            : "We'll help you find the place you love."}
        </Typography>
        <Grid className={classes.actionbar} container justifyContent="center">
          <Grid item xs={10} md={8}>
            <Input
              // classes={{ input: classes.addressInput }}
              style={{
                height: "100%",
                width: "100%",
                border: "0",
                outline: "none", //remove highlight arount input box
                backgroundColor: "white",
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
                fontFamily: "Arial",
                fontSize: "90%",
                paddingTop: "1px",
                paddingBottom: "1px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
              placeholder="Enter your email address"
              fullWidth
              disableUnderline
              //value="123 Main Street, Los Angeles, CA, USA"
              name="email"
              disabled={isDemo}
              value={isDemo ? demoForm.email : form.email || ""}
              onChange={this.handleChange}
              // error={errors.htmlTitle.length > 0}
              // helperText={errors.htmlTitle.length ? errors.htmlTitle : ""}
            />
          </Grid>
          <Grid item xs={2} className={classes.searchButton}>
            <Button
              className={classes.action}
              disabled={isFetching}
              onClick={this.handleClick}
            >
              {isFetching ? <Loading size="1rem" /> : <HouseIcon />}
            </Button>
          </Grid>
        </Grid>
        <div className={classes.spacer} />

        <Grid
          className={classes.reviewSpacer}
          container
          justifyContent="center"
          spacing={3}
        >
          <Grid item xs={12} sm={6}>
            <ReviewCard
              name="Nicole Lee"
              src={klevrTestimonialF}
              alt="Agent testimonial"
              testimonial="I was thinking about selling and decided to check my home's actual value. Turned out it was worth a few thousand more than I thought!"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReviewCard
              name="Brian Sanders"
              src={klevrTestimonialM}
              alt="Agent testimonial"
              testimonial="Amazingly helpful service and top notch agent! Can't thank you enough for the market analysis on my home."
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.buyer.form,
  demoForm: state.buyer.demoForm,
  step: state.buyer.step,
  isFetching: state.buyer.isFetching,
  landing: state.landings.landing,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, {
  updateForm,
  updateStep,
  createLeadBuyer,
  updateLeadBuyer,
})(withStyles(styles, { withTheme: true })(LandingStep1));
