import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Input,
  Button,
} from "@mui/material";
import Loading from "../../common/loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "../../theme/react-phone-input-2.css";
import { formatPhoneNumberIntoE164 } from "../../common/utils";
import {
  updateForm,
  updateStep,
  updateLeadBuyer,
} from "../../redux/buyer-actions";
import TagManager from "react-gtm-module";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(2),
    },
    primaryText: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
    },
    content: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    inputLabel: {
      color: "white !important",
      fontSize: theme.typography.subtitle1.fontSize,
    },
    input: {
      // marginTop: `${theme.spacing(3)} !important`,
      backgroundColor: "white",
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      "&:focus": {
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
      },
    },
    link: {
      textDecoration: "none",
    },
    containedButton: {
      backgroundColor: "#2694f1",
      marginTop: theme.spacing(2),
      // "&:hover": {
      //   backgroundColor: "#2694f1"
      // }
    },
    textButton: {
      color: "white",
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  successRoute: string;
}

class LandingStep4 extends React.Component<any> {
  override componentDidMount() {
    const { form, landing, updateForm } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      fromLandingPageId: landing.id,
      tags: "buyer",
      name: form.name || "",
      phoneNumber: form.phoneNumber || "",
    };
    updateForm(newForm);

    const tagManagerArgs = {
      gtmId: "GTM-N5HCWHV",
      events: {
        event: "partialLead",
      },
      dataLayer: {
        partialLead: true,
        userId: form.userId,
        leadId: form.id,
        tags: form.tags,
        landingPageId: form.fromLandingPageId,
        bedrooms: form.numberBedrooms,
        bathrooms: form.numberBathrooms,
        timeFrame: form.timeframe,
      },
      dataLayerName: "KlevrLandingPages",
    };

    TagManager.initialize(tagManagerArgs);
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;

    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    updateForm(newForm);
  };

  setPhoneNumber = (value: any, country: any, e: any, formattedValue: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      phoneNumber: "+" + value,
    };

    updateForm(newForm);
  };

  handleClick = (e: any) => {
    //e.preventDefault();
    const { form, isDemo, updateForm, updateStep, updateLeadBuyer } =
      this.props;

    if (isDemo) {
      updateStep("success");
      return;
    }

    let phoneNumber = formatPhoneNumberIntoE164(form.phoneNumber);

    if (phoneNumber && form.name.length > 0) {
      let newForm = {
        ...form,
        phoneNumber,
      };
      updateForm(newForm);

      updateLeadBuyer(newForm).then(() => {
        updateStep("success");
      });
    }
  };

  handleGoBack = (e: any) => {
    const { form, updateStep } = this.props;
    updateStep("2");
  };

  override render() {
    const { classes, form, demoForm, isFetching, isDemo } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.primaryText}>
          Should we text the list to you?
        </Typography>
        <Grid className={classes.content} container justifyContent="center">
          <Grid item xs={12} md={7}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="white">
                    Name
                  </Typography>
                  <Input
                    placeholder="Your name"
                    disabled={isDemo}
                    value={isDemo ? demoForm.name : form.name || ""}
                    onChange={this.handleChange}
                    className={classes.input}
                    name="name"
                    id="name"
                    disableUnderline
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" align="left" color="white">
                  Phone
                </Typography>
                <PhoneInput
                  inputProps={{
                    name: "phoneNumber",
                    label: "phoneNumber",
                    // required: true,
                  }}
                  inputStyle={{
                    background: "white",
                    width: "100%",
                    borderRadius: "4px",
                    height: "48px",
                    // borderBottom: "1px solid #878787",
                  }}
                  // specialLabel={"Phone Number"}
                  country={"us"} // initialcountry (in some cases behaves weirdly)
                  onlyCountries={["au", "ca", "it", "mx", "us", "es"]}
                  countryCodeEditable={false}
                  disableDropdown={true}
                  placeholder={"(555) 555-1234"}
                  disabled={isDemo}
                  value={isDemo ? demoForm.phoneNumber : form.phoneNumber || ""}
                  onChange={(value, country, e, formattedValue) =>
                    this.setPhoneNumber(value, country, e, formattedValue)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={11} sm={6} md={4}>
            <Button
              // className={classes.containedButton}
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              disabled={isFetching}
              onClick={this.handleClick}
            >
              {isFetching ? <Loading size="2rem" /> : "Next"}
            </Button>
            <Button
              className={classes.textButton}
              color="primary"
              size="large"
              fullWidth
              disabled={isFetching}
              onClick={this.handleGoBack}
            >
              Go back
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.buyer.form,
  demoForm: state.buyer.demoForm,
  step: state.buyer.step,
  isFetching: state.buyer.isFetching,
  landing: state.landings.landing,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, {
  updateForm,
  updateStep,
  updateLeadBuyer,
})(withStyles(styles, { withTheme: true })(LandingStep4));
