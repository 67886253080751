import {
  DEMO_ENABLE,
  DEMO_DISABLE,
  LANDING_FETCHING,
  LANDING_FETCHING_SUCCESS,
  LANDING_FETCHING_FAIL,
  VERIFY_CONFIRMATIONCODE,
  VERIFY_CONFIRMATIONCODE_SUCCESS,
  VERIFY_CONFIRMATIONCODE_FAIL,
  UPDATE_AND_FETCH_CLOUDCMA_REPORT,
  UPDATE_AND_FETCH_CLOUDCMA_REPORT_SUCCESS,
  UPDATE_AND_FETCH_CLOUDCMA_REPORT_FAIL,
} from "./landings-actions";

import { ReduxAction } from "./common";
//import { LandingInterface } from "./common";

interface LandingsState {
  isDemo: boolean;
  isFetching: boolean;
  error: boolean;
  landing: any;
  landingUser: any;
  isVerifyingConfirmationCode: boolean;
  confirmationLead: any;
  confirmationUser: any;
  confirmationCodeFail: boolean;
  isFetchingCloudCMAReport: boolean;
  cloudCMALink: string | null;
  errorFetchingCloudCMAReport: string;
}

const initialState: LandingsState = {
  isDemo: false,
  isFetching: false,
  error: false,
  landing: {},
  landingUser: null,
  isVerifyingConfirmationCode: false,
  confirmationLead: null,
  confirmationUser: null,
  confirmationCodeFail: false,
  isFetchingCloudCMAReport: false,
  cloudCMALink: null,
  errorFetchingCloudCMAReport: "",
};

export const landingsReducer = (
  state: LandingsState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;
  switch (type) {
    case UPDATE_AND_FETCH_CLOUDCMA_REPORT:
      return {
        ...state,
        isFetchingCloudCMAReport: true,
      };

    case UPDATE_AND_FETCH_CLOUDCMA_REPORT_SUCCESS:
      return {
        ...state,
        isFetchingCloudCMAReport: false,
        cloudCMALink: payload.cloudCMALink,
      };

    case UPDATE_AND_FETCH_CLOUDCMA_REPORT_FAIL:
      let errorFetchingCloudCMAReport = "wrong confirmation code";
      if (
        // CloudCMA error message in case there is no report available
        payload.errorMessage ===
        "Could not find any comparable listings on the MLS around that subject property address."
      ) {
        errorFetchingCloudCMAReport = "no report available";
      }
      return {
        ...state,
        isFetchingCloudCMAReport: false,
        cloudCMALink: null,
        errorFetchingCloudCMAReport,
      };

    case VERIFY_CONFIRMATIONCODE:
      return {
        ...state,
        isVerifyingConfirmationCode: true,
        confirmationLead: null,
        confirmationUser: null,
        confirmationCodeFail: false,
      };

    case VERIFY_CONFIRMATIONCODE_SUCCESS:
      return {
        ...state,
        isVerifyingConfirmationCode: false,
        confirmationLead: payload.confirmationLead,
        confirmationUser: payload.confirmationUser,
        confirmationCodeFail: false,
      };

    case VERIFY_CONFIRMATIONCODE_FAIL:
      return {
        ...state,
        isVerifyingConfirmationCode: false,
        confirmationLead: null,
        confirmationUser: null,
        confirmationCodeFail: true,
      };

    case LANDING_FETCHING:
      return {
        ...state,
        landing: {},
        landingUser: null,
        isFetching: true,
        error: false,
      };

    case LANDING_FETCHING_SUCCESS:
      return {
        ...state,
        landing: payload.landing,
        landingUser: payload.landingUser,
        isFetching: false,
        error: false,
      };

    case LANDING_FETCHING_FAIL:
      return {
        ...state,
        landing: {},
        landingUser: null,
        isFetching: false,
        error: true,
      };

    case DEMO_ENABLE:
      return {
        ...state,
        isDemo: true,
      };

    case DEMO_DISABLE:
      return {
        ...state,
        isDemo: false,
      };

    default:
      return state;
  }
};
