import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import MainRoute from "./main";
import Success from "./success";
import LandingPagesHeader from "./common/landing-pages-header";
import LandingPagesFooter from "./common/landing-pages-footer";
import ErrorNotFound from "../common/error-not-found";
import Loading from "../common/loading";
import Rooftops from "../common/background-images/Rooftops.jpg";
import { isEmptyObj, isStepOne } from "../common/utils";

import Step1 from "./step1";
//import Step2 from "./step_x";
import Step2 from "./step2";
import Step3 from "./step3";

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "100%",
      height: "100%",
      // [theme.breakpoints.down("xs")]: {
      //   height: "100%"
      // },
      background: `linear-gradient(0deg,rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(${Rooftops}),no-repeat center fixed`,
      backgroundSize: "cover",
    },
    rootOne: {
      width: "100%",
      height: "100%",
      // [theme.breakpoints.down("xs")]: {
      //   height: "100%"
      // }
      // backgroundColor: "#1C2136",
      // overflow: "auto"
    },
    root: {
      height: "100vh",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
      // backgroundColor: "#1C2136",
      overflow: "auto",
    },
    container: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      color: "white",
      maxWidth: 1024,
      margin: "auto",
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(2),
    },
    content: {
      // flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  });

class LandingRoute extends React.Component<any> {
  renderLanding(
    classes: any,
    location: any,
    match: any,
    form: any,
    step: any,
    landing: any,
    successRoute: any
  ) {
    const templateId = match.params.templateId;
    const landingPageId = match.params.landingPageId;
    const stepString = match.params.stepString;

    return (
      <div className={classes.rootOne}>
        {/* <div className={isStepOne(step) ? classes.main : classes.rootOne}> */}
        <div className={classes.rootOne}>
          <div className={classes.container}>
            {/* <LandingPagesHeader location={location} /> */}
            <main className={classes.content}>
              <Switch>
                {stepString !== "step1" && step === "1" && (
                  <Redirect to={`/lp/${templateId}/${landingPageId}/step1`} />
                )}
                {stepString !== "step2" && step === "2" && (
                  <Redirect to={`/lp/${templateId}/${landingPageId}/step2`} />
                )}
                {stepString !== "step3" && step === "3" && (
                  <Redirect to={`/lp/${templateId}/${landingPageId}/step3`} />
                )}
                {stepString !== "success" && step === "success" && (
                  <Redirect to={`/lp/${templateId}/${landingPageId}/success`} />
                )}

                {stepString === "step1" && <Route component={Step1} />}
                {stepString === "step2" && <Route component={Step2} />}
                {stepString === "step3" && <Route component={Step3} />}
                {stepString === "success" && <Route component={Success} />}
              </Switch>
            </main>
            {/* <LandingPagesFooter /> */}
          </div>
        </div>
      </div>
    );
  }

  override render() {
    const {
      classes,
      location,
      match,
      isDemo,
      form,
      step,
      isFetching,
      errorLanding,
      landing,
    } = this.props;

    const successRoute = match.path + "/success";

    return (
      <div>
        {isDemo &&
          this.renderLanding(
            classes,
            location,
            match,
            form,
            step,
            landing,
            successRoute
          )}

        {!isDemo && isFetching && <Loading />}

        {!isDemo && !isFetching && errorLanding && <ErrorNotFound />}

        {!isDemo &&
          !isFetching &&
          !errorLanding &&
          !isEmptyObj(landing) &&
          this.renderLanding(
            classes,
            location,
            match,
            form,
            step,
            landing,
            successRoute
          )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.buyer.form,
  step: state.buyer.step,
  isDemo: state.landings.isDemo,
  isFetching: state.landings.isFetching,
  errorLanding: state.landings.error,
  landing: state.landings.landing,
});

export default connect(mapStateToProps)(withStyles(styles)(LandingRoute));
