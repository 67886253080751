import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import NameInput from "../../common/page-components/name-input";
import { colors } from "../../common/colors";

const styles = (theme: Theme) =>
  createStyles({
    selectLabel: {
      color: colors.darkBlue,
      fontSize: theme.typography.subtitle1.fontSize,
    },
    input: {
      // marginTop: `${theme.spacing(3)} !important`,
      backgroundColor: "white",
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      "&:focus": {
        backgroundColor: colors.lightGrey,
        borderRadius: theme.spacing(0.5),
      },
    },
  });

export default withStyles(styles)(NameInput);
