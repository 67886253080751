import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Button,
} from "@mui/material";
import TagManager from "react-gtm-module";
import Loading from "../../common/loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "../../theme/react-phone-input-2.css";
import isEmail from "validator/lib/isEmail";
import {
  updateForm,
  updateStep,
  updateLeadSeller,
} from "../../redux/seller-actions";
import { formatPhoneNumberIntoE164 } from "../../common/utils";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(2),
      marginBottom: "20vh",
    },
    primaryText: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: "5vh",
        fontSize: "3vh",
      },
    },
    goBackText: {
      color: "white",
      opacity: 0.5,
      textAlign: "center",
    },
    content: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    inputLabel: {
      color: "white !important",
      fontSize: theme.typography.subtitle1.fontSize,
    },
    input: {
      // marginTop: `${theme.spacing(3)} !important`,
      backgroundColor: "white",
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      "&:focus": {
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
      },
    },
    selectLabel: {
      color: "white !important",
      // fontSize: theme.typography.subtitle1.fontSize,
    },
    select: {
      backgroundColor: "white",
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      "&:focus": {
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
      },
    },
    link: {
      textDecoration: "none",
    },
    containedButton: {
      backgroundColor: "#2694f1",
      marginTop: theme.spacing(2),
      // "&:hover": {
      //   backgroundColor: "#04D263"
      // }
    },
    textButton: {
      color: "white",
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  successRoute: string;
}

class LandingStep4 extends React.Component<any> {
  override componentDidMount() {
    const { form, landing, updateForm } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      name: form.name || "",
      email: form.email || "",
      fromLandingPageId: landing.id,
      tags: ["seller"],
      timeframe: form.timeframe || "1-3 months",
      phoneNumber: form.phoneNumber || "",
    };
    updateForm(newForm);

    const tagManagerArgs = {
      gtmId: "GTM-N5HCWHV",
      events: {
        event: "partialLead",
      },
      dataLayer: {
        partialLead: true,
        userId: form.userId,
        leadId: form.id,
        landingPageId: form.fromLandingPageId,
        zipCode: form.zipcodeAddress,
        city: form.cityAddress,
        state: form.stateAddress,
        tags: form.tags,
        propertyEstimate: form.zestimate,
        bedrooms: form.numberBedrooms,
        bathrooms: form.numberBathrooms,
        squareFeet: form.squareFeet,
        timeFrame: form.timeframe,
      },
      dataLayerName: "KlevrLandingPages",
    };

    TagManager.initialize(tagManagerArgs);
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    updateForm(newForm);
  };

  setPhoneNumber = (value: any, country: any, e: any, formattedValue: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      phoneNumber: "+" + value,
    };

    updateForm(newForm);
  };

  handleCalculateValue = (e: any) => {
    //e.preventDefault();
    const { form, isDemo, updateForm, updateStep, updateLeadSeller } =
      this.props;

    if (isDemo) {
      updateStep("success");
      return;
    }

    let phoneNumber = formatPhoneNumberIntoE164(form.phoneNumber);

    if (
      phoneNumber &&
      // form.timeframe.length > 0 &&
      isEmail(form.email) &&
      form.name.length > 0
    ) {
      let newForm = {
        ...form,
        phoneNumber,
      };
      updateForm(newForm);

      updateLeadSeller(newForm).then(() => {
        updateStep("success");
      });
    }
  };

  handleGoBack = (e: any) => {
    const { form, updateStep } = this.props;
    updateStep("3");
  };

  override render() {
    const { classes, form, demoForm, landing, isFetching, isDemo } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.primaryText}>
          We're crunching the numbers... Almost Done!
        </Typography>
        <Grid className={classes.content} container justifyContent="center">
          <Grid item xs={8}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="white">
                    Name
                  </Typography>
                  <Input
                    placeholder="Your name"
                    className={classes.input}
                    name="name"
                    id="name"
                    disableUnderline
                    disabled={isDemo}
                    value={isDemo ? demoForm.name : form.name || ""}
                    onChange={this.handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" align="left" color="white">
                  Phone
                </Typography>
                <PhoneInput
                  inputProps={{
                    name: "phoneNumber",
                    label: "phoneNumber",
                    // required: true,
                  }}
                  inputStyle={{
                    background: "white",
                    width: "100%",
                    borderRadius: "4px",
                    height: "48px",
                    // borderBottom: "1px solid #878787",
                  }}
                  // specialLabel={"Phone Number"}
                  country={"us"} // initialcountry (in some cases behaves weirdly)
                  onlyCountries={["au", "ca", "it", "mx", "us", "es"]}
                  countryCodeEditable={false}
                  disableDropdown={true}
                  placeholder={"For verification purposes only"}
                  disabled={isDemo}
                  value={isDemo ? demoForm.phoneNumber : form.phoneNumber || ""}
                  onChange={(value, country, e, formattedValue) =>
                    this.setPhoneNumber(value, country, e, formattedValue)
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="white">
                    Email
                  </Typography>
                  <Input
                    placeholder="Your email address"
                    className={classes.input}
                    name="email"
                    id="email"
                    disableUnderline
                    disabled={isDemo}
                    value={isDemo ? demoForm.email : form.email || ""}
                    onChange={this.handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={8} sm={6} md={4}>
            <Button
              // className={classes.containedButton}
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              disabled={isFetching}
              onClick={this.handleCalculateValue}
            >
              {isFetching ? <Loading size="1rem" /> : "Calculate my value"}
            </Button>
            <Button
              className={classes.goBackText}
              color="primary"
              size="large"
              fullWidth
              disabled={isFetching}
              onClick={this.handleGoBack}
            >
              Go back
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.seller.form,
  demoForm: state.seller.demoForm,
  step: state.seller.step,
  isFetching: state.seller.isFetching,
  landing: state.landings.landing,
  landingUser: state.landings.landingUser,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, {
  updateForm,
  updateStep,
  updateLeadSeller,
})(withStyles(styles, { withTheme: true })(LandingStep4));
