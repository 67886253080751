import api from "../api/api";

export const INITIALIZE_SELLER_FORM = "INITIALIZE_SELLER_FORM";
export const SELLER_FORM_UPDATED = "SELLER_FORM_UPDATED";
export const SELLER_STEP_UPDATED = "SELLER_STEP_UPDATED";
export const SELLER_LEAD_CREATING = "SELLER_LEAD_CREATING";
export const SELLER_LEAD_CREATING_SUCCESS = "SELLER_LEAD_CREATING_SUCCESS";
export const SELLER_LEAD_CREATING_FAIL = "SELLER_LEAD_CREATING_FAIL";
export const SELLER_LEAD_UPDATING = "SELLER_LEAD_UPDATING";
export const SELLER_LEAD_UPDATING_SUCCESS = "SELLER_LEAD_UPDATING_SUCCESS";
export const SELLER_LEAD_UPDATING_FAIL = "SELLER_LEAD_UPDATING_FAIL";
export const SET_GOOGLE_INFO = "SET_GOOGLE_INFO";
export const SET_GOOGLE_INFO_SUCCESS = "SET_GOOGLE_INFO_SUCCESS";
export const SET_GOOGLE_INFO_FAIL = "SET_GOOGLE_INFO_FAIL";
export const SET_ZILLOW_INFO = "SET_ZILLOW_INFO";
export const SET_ZILLOW_INFO_SUCCESS = "SET_ZILLOW_INFO_SUCCESS";
export const SET_ZILLOW_INFO_FAIL = "SET_ZILLOW_INFO_FAIL";

export const updateLeadSeller = (leadForm: any) => (dispatch: Function) => {
  dispatch({
    type: SELLER_LEAD_UPDATING,
  });

  return api.leads
    .updateOne(leadForm)
    .then((lead: any) => {
      dispatch({
        type: SELLER_LEAD_UPDATING_SUCCESS,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: SELLER_LEAD_UPDATING_FAIL,
      });
    });
};

export const createLeadSeller = (leadForm: any) => (dispatch: Function) => {
  dispatch({
    type: SELLER_LEAD_CREATING,
  });

  return api.leads
    .createOne(leadForm)
    .then((lead: any) => {
      dispatch({
        type: SELLER_LEAD_CREATING_SUCCESS,
        payload: { lead },
      });
    })
    .catch((err: any) => {
      dispatch({
        type: SELLER_LEAD_CREATING_FAIL,
      });
    });
};

export const updateForm = (form: any) => (dispatch: Function) => {
  dispatch({
    type: SELLER_FORM_UPDATED,
    payload: { form },
  });
};

export const updateStep = (step: any) => (dispatch: Function) => {
  dispatch({
    type: SELLER_STEP_UPDATED,
    payload: { step },
  });
};

export const getEstimate = (params: any) => async (dispatch: Function) => {
  dispatch({
    type: SET_ZILLOW_INFO,
  });

  return api.zillow
    .GetSearchResults(params)
    .then((res: any) => {
      const payload = {
        zestimate: res[0].zestimate[0].amount[0]._,
        zhigh: res[0].zestimate[0].valuationRange[0].high[0]._,
        zlow: res[0].zestimate[0].valuationRange[0].low[0]._,
        zpid: res[0].zpid[0],
      };

      dispatch({
        type: SET_ZILLOW_INFO_SUCCESS,
        payload,
      });

      return payload;
    })
    .catch((err: any) => {
      dispatch({
        type: SET_ZILLOW_INFO_FAIL,
      });
    });
};

export const getGeocode = (rawAddress: any) => async (dispatch: Function) => {
  dispatch({
    type: SET_GOOGLE_INFO,
  });

  return api.googleMaps
    .geocode(rawAddress)
    .then((results: any) => {
      const { formatted_address } = results;

      const newRawAddress = formatted_address;

      const routeAddress = formatted_address.substring(
        0,
        formatted_address.indexOf(",")
      );

      const cityAddress =
        results.address_components.filter(
          (obj: any) => obj.types[0] === "locality"
        )[0].long_name || "";

      const stateAddress =
        results.address_components.filter(
          (obj: any) => obj.types[0] === "administrative_area_level_1"
        )[0].short_name || "";

      const zipcodeAddress =
        results.address_components.filter(
          (obj: any) => obj.types[0] === "postal_code"
        )[0].long_name || "";

      const { lat, lng } = results.geometry.location;

      console.log(stateAddress);

      dispatch({
        type: SET_GOOGLE_INFO_SUCCESS,
        payload: {
          rawAddress: newRawAddress,
          formatted_address,
          routeAddress,
          cityAddress,
          stateAddress,
          zipcodeAddress,
          lat,
          lng,
        },
      });
    })
    .catch((err: any) => {
      console.log("Error in Google's Geocode query");
      dispatch({
        type: SET_GOOGLE_INFO_FAIL,
      });
    });
};
