import React from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";

import { googleApiKey } from "../../api/api";

//Library used to integrate Google Maps:
// https://github.com/tomchentw/react-google-maps/
// https://github.com/tomchentw/react-google-maps/issues/323
// https://react-google-maps-api-docs.netlify.com/
// https://tomchentw.github.io/react-google-maps/

//Tutorials:
// https://medium.com/@yelstin.fernandes/render-a-map-component-using-react-google-maps-5f7fb3e418bb
// https://medium.com/@morgannegagne/google-maps-with-react-951c12b723ad
// https://medium.com/@imranhsayed/google-maps-in-react-autocomplete-location-search-draggable-marker-marker-infobox-565ab8e8cf22

//Other libraries:
// https://github.com/JustFly1984/react-google-maps-api (improvement of react-google-maps)
// https://github.com/google-map-react/google-map-react
// https://github.com/fullstackreact/google-maps-react

interface Props {
  height: string;
  markerLat: string;
  markerLng: string;
}

class MapWithMarker extends React.Component<Props> {
  override render() {
    const { height, markerLat, markerLng } = this.props;

    const GoogleMapInternal = withScriptjs(
      withGoogleMap((props: any) => (
        <GoogleMap
          defaultCenter={{ lat: markerLat, lng: markerLng }}
          defaultZoom={18}
          defaultOptions={{
            scrollwheel: false,
            zoomControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
          }}
          mapTypeId="satellite"
        >
          <Marker position={{ lat: markerLat, lng: markerLng }} />
        </GoogleMap>
      ))
    );

    const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}`;

    return (
      <div>
        <GoogleMapInternal
          googleMapURL={googleMapURL}
          loadingElement={<div style={{ height: height, width: `100%` }} />}
          containerElement={<div style={{ height: height, width: `100%` }} />}
          mapElement={<div style={{ height: height, width: `100%` }} />}
        />
      </div>
    );
  }
}

export default MapWithMarker;
