import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const primary = {
  light: "#636363",
  main: "#393939",
  dark: "#131313",
  contrastText: "#00d88e",
};
const secondary = {
  light: "#62ffbf",
  main: "#00d88e",
  dark: "#00a560",
  contrastText: "#ffffff",
};

let theme = createTheme({
  palette: {
    primary,
    secondary,
  },
});
theme = responsiveFontSizes(theme);

export default theme;
