import React, { RefObject } from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import { Typography, Grid, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Divider from "@mui/material/Divider";
import classNames from "classnames";
import TagManager from "react-gtm-module";
import isEmail from "validator/lib/isEmail";

import Loading from "../../common/loading";
import MapWithMarker from "../common/map-with-marker";
import { colors } from "../../common/colors";

import NameInput from "../common/name-input";
import EmailInput from "../common/email-input";

import {
  updateForm,
  updateStep,
  updateLeadSeller,
} from "../../redux/seller-actions";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    primaryText: {
      color: colors.darkBlue,
      fontWeight: "bold",
      textAlign: "center",
    },
    secondaryText: {
      color: colors.darkBlue,
      fontWeight: 500,
      marginTop: theme.spacing(2),
      textAlign: "center",
    },
    whiteText: {
      color: colors.darkBlue,
      textAlign: "center",
      marginLeft: "50px",
      marginRight: "50px",
    },
    estimatedValues: {
      lineHeight: 1,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    map: {
      backgroundColor: theme.palette.grey[400],
      borderRadius: theme.spacing(0.5),
    },
    containedButton: {
      backgroundColor: colors.klevrGreen,
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: colors.klevrGreenDark,
      },
      maxWidth: "90%",
    },
    ctaBackground: {
      backgroundColor: "#eee",
    },
    cmaWrapper: {
      backgroundColor: colors.lightGrey,
      padding: "32px 16px",
      borderRadius: "5px",
    },
    ctaWrapper: {
      width: "500px",
      maxWidth: "90vw",
      margin: "16px auto",
      padding: "0 16px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    ctaElementWrapper: {
      display: "flex",
      color: colors.klevrGreen,
      justifyContent: "center",
      marginBottom: "16px",
    },
    ctaIconWrapper: {
      margin: "16px",
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
        marginLeft: "-8px",
      },
    },
    ctaTextWrapper: {
      textAlign: "left",
      paddingLeft: "16px",
    },
    inputWrapper: {
      marginTop: "16px",
    },
  });

interface Props extends WithStyles<typeof styles> {
  //
}

class LandingStep3 extends React.Component<any> {
  private cmaRef = React.createRef<HTMLDivElement>();

  override componentDidMount() {
    const { form, landing, updateForm } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      fromLandingPageId: landing.id,
      tags: ["seller"],
      name: form.name || "",
      email: form.email || "",
      numberBedrooms: form.numberBedrooms || "1",
      numberBathrooms: form.numberBathrooms || "1",
      squareFeet: form.squareFeet || "0-1000",
      timeframe: form.timeframe || "1-3 months",
      // timeframe: "",
      phoneNumber: "",
    };
    updateForm(newForm);

    const tagManagerArgs = {
      gtmId: "GTM-N5HCWHV",
      events: {
        event: "addressCapture",
      },
      dataLayer: {
        addressCapture: true,
        userId: form.userId,
        leadId: form.id,
        landingPageId: form.fromLandingPageId,
        zipCode: form.zipcodeAddress,
        city: form.cityAddress,
        state: form.stateAddress,
        tags: form.tags,
        propertyEstimate: form.zestimate,
      },
      dataLayerName: "KlevrLandingPages",
    };

    TagManager.initialize(tagManagerArgs);
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    updateForm(newForm);
  };

  handleCalculateValue = (e: any) => {
    const { form, isDemo, updateStep, updateLeadSeller } = this.props;

    if (isDemo) {
      updateStep("4");
      return;
    }

    if (form.name.length > 0 && isEmail(form.email)) {
      updateLeadSeller(form).then(() => {
        updateStep("4");
      });
    }
  };

  handleGoBack = (e: any) => {
    const { updateStep } = this.props;
    updateStep("2");
  };

  override render() {
    const { classes, form, demoForm, landing, isFetching, isDemo } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="subtitle1" className={classes.secondaryText}>
          {isDemo ? demoForm.formatted_address : form.formatted_address}
        </Typography>

        <Grid className={classes.content} container justifyContent="center">
          <Grid item xs={12} md={8}>
            <div className={classes.map}>
              <MapWithMarker
                height="20vh"
                markerLat={isDemo ? demoForm.lat : form.lat}
                markerLng={isDemo ? demoForm.lng : form.lng}
              />
            </div>
            <div>
              {isDemo || form.zestimate ? (
                <Typography
                  variant="h6"
                  className={classNames(
                    classes.estimatedValues,
                    classes.whiteText
                  )}
                >
                  We've estimated your home's value at{" "}
                  <span style={{ color: colors.klevrGreen }}>
                    {isDemo
                      ? parseFloat(demoForm.zestimate).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : parseFloat(form.zestimate).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                  </span>
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  className={classNames(
                    classes.estimatedValues,
                    classes.whiteText
                  )}
                >
                  Sorry, we couldn't find an estimation for your property in our
                  database.
                </Typography>
              )}
            </div>
            <div className={classes.cmaWrapper}>
              <Typography variant="h5" className={classes.primaryText}>
                NEXT IMPORTANT STEP!
              </Typography>
              <Typography variant="subtitle1" className={classes.secondaryText}>
                Get Your Comparative Market Analysis (CMA) Now!
              </Typography>
              <Button
                // className={classes.containedButton}
                variant="contained"
                color="secondary"
                size="large"
                // fullWidth
                onClick={() => {
                  if (this.cmaRef && this.cmaRef.current) {
                    this.cmaRef.current.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                Get My CMA
              </Button>
              <Divider variant="middle" style={{ margin: "16px" }} />
              <div className={classes.ctaWrapper}>
                <div className={classes.ctaElementWrapper}>
                  <div className={classes.ctaIconWrapper}>
                    <AddLocationIcon style={{ fontSize: "50px" }} />
                  </div>
                  <div className={classes.ctaTextWrapper}>
                    <Typography variant="h6">Compare Your Home</Typography>
                    <Typography color="textPrimary">
                      Instant side by side comparison of what your neighbors are
                      selling their homes for.
                    </Typography>
                  </div>
                </div>
                <div className={classes.ctaElementWrapper}>
                  <div className={classes.ctaIconWrapper}>
                    <LockOpenIcon style={{ fontSize: "50px" }} />
                  </div>
                  <div className={classes.ctaTextWrapper}>
                    <Typography variant="h6">Insider Access</Typography>
                    <Typography color="textPrimary">
                      Exclusive access to recently sold & listed properties that
                      are not publicly available.
                    </Typography>
                  </div>
                </div>
                <div className={classes.ctaElementWrapper}>
                  <div className={classes.ctaIconWrapper}>
                    <FlashOnIcon style={{ fontSize: "50px" }} />
                  </div>
                  <div className={classes.ctaTextWrapper}>
                    <Typography variant="h6">Immediate Delivery!</Typography>
                    <Typography color="textPrimary">
                      Don't waste your time, get all of the information you need
                      to make an informed decision RIGHT NOW!
                    </Typography>
                  </div>
                </div>
              </div>
              <Divider variant="middle" style={{ margin: "16px" }} />
              <Grid
                className={classes.ctaBackground}
                container
                justifyContent="center"
              >
                <Grid item xs={12} md={6}>
                  <div ref={this.cmaRef} />
                  <Typography
                    color="textPrimary"
                    variant="h6"
                    style={{ marginBottom: "16px" }}
                  >
                    Get Your CMA Today!
                  </Typography>
                  <Grid container justifyContent="center" spacing={2}>
                    <NameInput
                      isDemo={isDemo}
                      demoForm={demoForm}
                      form={form}
                      onChange={this.handleChange}
                    />
                    <EmailInput
                      isDemo={isDemo}
                      demoForm={demoForm}
                      form={form}
                      onChange={this.handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className={classes.inputWrapper}
                container
                justifyContent="center"
              >
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <Button
                    // className={classes.containedButton}
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    disabled={isFetching}
                    onClick={this.handleCalculateValue}
                  >
                    {isFetching ? <Loading size="1rem" /> : "Get My CMA"}
                    <ArrowForwardIcon />
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.seller.form,
  demoForm: state.seller.demoForm,
  step: state.seller.step,
  isFetching: state.seller.isFetching,
  landing: state.landings.landing,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, {
  updateForm,
  updateStep,
  updateLeadSeller,
})(withStyles(styles, { withTheme: true })(LandingStep3));
