import React from "react";

import { withStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "100px",
      textAlign: "center",
    },
  });

const ErrorNotFound = (props: any) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <h3>Sorry, page not found!</h3>
    </div>
  );
};

export default withStyles(styles)(ErrorNotFound);
