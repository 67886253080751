import { parsePhoneNumberWithError } from "libphonenumber-js";

export const formatPhoneNumberIntoE164 = (phoneNumber: string) => {
  //if phoneNumber is parseable then it is returned in E.164 format,
  //otherwise the empty string is returned.
  try {
    let formattedPhoneNumber = parsePhoneNumberWithError(phoneNumber, "US");
    //the following line can check if the parsed number is also a valid one
    //an example of valid US number is "(702) 365-1234"
    //console.log(formattedPhoneNumber ? formattedPhoneNumber.isValid() : "");
    //
    //Output number is of type E164Number, so it's convenient to cast it.
    //https://github.com/catamphetamine/libphonenumber-js/blob/master/types.d.ts
    return formattedPhoneNumber ? String(formattedPhoneNumber.number) : "";
  } catch (error: any) {
    return "";
  }
};

export const parseRouteIdFromProps = (props: any, idKey = "id"): number =>
  parseInt(props.match.params[idKey], 10);

export const isEmptyObj = (obj: any) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

export const isStepOne = (step: any) => step === "1";

export const isValidConfirmationCode = (confirmationCode: string): boolean => {
  const validConfirmationCodeLength = 6;

  if (confirmationCode.length !== validConfirmationCodeLength) {
    return false;
  }

  if (!/^[ABCDEFGHJKLMNPQRTWXY]+$/.test(confirmationCode[0])) {
    return false;
  }

  if (!/^[ABCDEFGHJKLMNPQRTWXY23456789]+$/.test(confirmationCode)) {
    return false;
  }

  return true;
};
