import React from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Button,
} from "@mui/material";
import TagManager from "react-gtm-module";
import isEmail from "validator/lib/isEmail";
import {
  updateForm,
  updateStep,
  updateLeadSeller,
} from "../../redux/seller-actions";
import Loading from "../../common/loading";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(2),
      marginBottom: "20vh",
    },
    goBackText: {
      color: "white",
      opacity: 0.5,
      textAlign: "center",
    },
    primaryText: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: "5vh",
        fontSize: "3vh",
      },
    },
    content: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    selectLabel: {
      color: "white !important",
      fontSize: theme.typography.subtitle1.fontSize,
    },
    input: {
      // marginTop: `${theme.spacing(3)} !important`,
      backgroundColor: "white",
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      "&:focus": {
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
      },
    },
    selectInput: {
      // marginTop: `${theme.spacing(3)} !important`,
    },
    select: {
      backgroundColor: "white",
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      "&:focus": {
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
      },
    },
    containedButton: {
      backgroundColor: "#2694f1",
      marginTop: theme.spacing(2),
      // "&:hover": {
      //   backgroundColor: "#04D263"
      // }
    },
    textButton: {
      color: "white",
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  //
}

class LandingStep3 extends React.Component<any> {
  override componentDidMount() {
    const { form, landing, updateForm } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      fromLandingPageId: landing.id,
      tags: ["seller"],
      name: form.name || "",
      email: form.email || "",
      numberBedrooms: form.numberBedrooms || "1",
      numberBathrooms: form.numberBathrooms || "1",
      squareFeet: form.squareFeet || "0-1000",
      timeframe: form.timeframe || "1-3 months",
      // timeframe: "",
      phoneNumber: "",
    };
    updateForm(newForm);

    const tagManagerArgs = {
      gtmId: "GTM-N5HCWHV",
      events: {
        event: "addressCapture",
      },
      dataLayer: {
        addressCapture: true,
        userId: form.userId,
        leadId: form.id,
        landingPageId: form.fromLandingPageId,
        zipCode: form.zipcodeAddress,
        city: form.cityAddress,
        state: form.stateAddress,
        propertyEstimate: form.zestimate,
      },
      dataLayerName: "KlevrLandingPages",
    };

    TagManager.initialize(tagManagerArgs);
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    updateForm(newForm);
  };

  handleCalculateValue = (e: any) => {
    const { form, isDemo, updateStep, updateLeadSeller } = this.props;

    if (isDemo) {
      updateStep("4");
      return;
    }

    if (
      // form.name.length > 0 &&
      // isEmail(form.email) &&
      form.timeframe.length > 0 &&
      form.numberBedrooms.length > 0 &&
      form.numberBathrooms.length > 0 &&
      form.squareFeet.length > 0
    ) {
      updateLeadSeller(form).then(() => {
        updateStep("4");
      });
    }
  };

  handleGoBack = (e: any) => {
    const { updateStep } = this.props;
    updateStep("2");
  };

  override render() {
    const { classes, form, demoForm, landing, isFetching, isDemo } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.primaryText}>
          Nice place! Can you verify the details?
        </Typography>
        <Grid className={classes.content} container justifyContent="center">
          <Grid item xs={8} md={8}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="white">
                    Number of bedrooms
                  </Typography>
                  <Select
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDemo}
                    value={
                      isDemo
                        ? demoForm.numberBedrooms
                        : form.numberBedrooms || ""
                    }
                    input={
                      <Input
                        onChange={this.handleChange}
                        className={classes.selectInput}
                        name="numberBedrooms"
                        id="numberBedrooms"
                        disableUnderline
                      />
                    }
                    displayEmpty
                    name="numberBedrooms"
                  >
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                    <MenuItem value={"5+"}>5+</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="white">
                    Number of Bathrooms
                  </Typography>
                  <Select
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDemo}
                    value={
                      isDemo
                        ? demoForm.numberBathrooms
                        : form.numberBathrooms || ""
                    }
                    input={
                      <Input
                        onChange={this.handleChange}
                        className={classes.selectInput}
                        name="numberBathrooms"
                        id="numberBathrooms"
                        disableUnderline
                      />
                    }
                    displayEmpty
                    name="numberBathrooms"
                  >
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                    <MenuItem value={"5+"}>5+</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="white">
                    Square Feet
                  </Typography>
                  <Select
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDemo}
                    value={isDemo ? demoForm.squareFeet : form.squareFeet}
                    input={
                      <Input
                        onChange={this.handleChange}
                        className={classes.selectInput}
                        name="squareFeet"
                        id="squareFeet"
                        disableUnderline
                      />
                    }
                    displayEmpty
                    name="squareFeet"
                  >
                    <MenuItem value={"0-1000"}>0-1000</MenuItem>
                    <MenuItem value={"1000-2000"}>1000-2000</MenuItem>
                    <MenuItem value={"2000-3000"}>2000-3000</MenuItem>
                    <MenuItem value={"3000-4000"}>3000-4000</MenuItem>
                    <MenuItem value={"4000-5000"}>4000-5000</MenuItem>
                    <MenuItem value={"5000+"}>5000+</MenuItem>
                    <MenuItem value={"Not sure"}>Not sure</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="white">
                    Selling In
                  </Typography>
                  <Select
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDemo}
                    value={isDemo ? demoForm.timeframe : form.timeframe}
                    input={
                      <Input
                        onChange={this.handleChange}
                        className={classes.selectInput}
                        name="timeframe"
                        id="timeframe"
                        disableUnderline
                      />
                    }
                    displayEmpty
                    name="timeframe"
                  >
                    <MenuItem value={"1-3 months"}>1-3 months</MenuItem>
                    <MenuItem value={"3-6 months"}>3-6 months</MenuItem>
                    <MenuItem value={"6-12 months"}>6-12 months</MenuItem>
                    <MenuItem value={"Just curious"}>Just curious</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={8} sm={6} md={4}>
            <Button
              // className={classes.containedButton}
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              disabled={isFetching}
              onClick={this.handleCalculateValue}
            >
              {isFetching ? (
                <Loading size="1rem" color="primary" />
              ) : (
                "Calculate my value"
              )}
            </Button>
            <Button
              className={classes.goBackText}
              color="primary"
              size="large"
              fullWidth
              disabled={isFetching}
              onClick={this.handleGoBack}
            >
              Go back
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.seller.form,
  demoForm: state.seller.demoForm,
  step: state.seller.step,
  isFetching: state.seller.isFetching,
  landing: state.landings.landing,
  landingUser: state.landings.landingUser,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, {
  updateForm,
  updateStep,
  updateLeadSeller,
})(withStyles(styles, { withTheme: true })(LandingStep3));
