import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import { Typography, Grid, Button, Divider } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import MapWithMarker from "./common/map-with-marker";

import TagManager from "react-gtm-module";
import { colors } from "../common/colors";
import { updateForm, updateStep } from "../redux/seller-actions";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    primaryText: {
      color: colors.darkBlue,
      fontWeight: "bold",
      textAlign: "center",
    },
    secondaryText: {
      color: colors.darkBlue,
      opacity: 0.5,
      fontWeight: 500,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(),
      textAlign: "center",
    },
    darkText: {
      color: colors.darkBlue,
      textAlign: "center",
      marginLeft: "50px",
      marginRight: "50px",
    },
    whiteText: {
      color: "white",
      textAlign: "center",
    },
    content: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    map: {
      backgroundColor: theme.palette.grey[400],
      borderRadius: theme.spacing(0.5),
      marginBottom: "16px",
    },
    description: {
      color: "#00d88e",
      textAlign: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    lockIcon: {
      fontSize: 14,
      marginRight: theme.spacing(0.5),
    },
    footerText: {
      color: colors.darkBlue,
      textAlign: "center",
      fontSize: "16px",
      marginBottom: "16px",
    },
    link: {
      color: "#00d88e",
    },
    owners: {
      backgroundColor: "#2E3B67",
      borderRadius: theme.spacing(0.5),
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
    },
    estimatedValues: {
      backgroundColor: "#00d88e",
      borderRadius: theme.spacing(0.5),
      lineHeight: 1,
      marginLeft: -theme.spacing(),
      marginRight: -theme.spacing(),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    containedButton: {
      width: 240,
      color: "white",
      backgroundColor: colors.klevrGreen,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      "&:hover": {
        backgroundColor: colors.klevrGreenDark,
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  t: any;
}

class LandingSuccess extends React.Component<any> {
  override componentDidMount() {
    const { form } = this.props;
    const tagManagerArgs = {
      gtmId: "GTM-N5HCWHV",
      events: {
        event: "fullLead",
      },
      dataLayer: {
        fullLead: true,
        userId: form.userId,
        leadId: form.id,
        landingPageId: form.fromLandingPageId,
        zipCode: form.zipcodeAddress,
        city: form.cityAddress,
        state: form.stateAddress,
        tags: form.tags,
        propertyEstimate: form.zestimate,
        bedrooms: form.numberBedrooms,
        bathrooms: form.numberBathrooms,
        squareFeet: form.squareFeet,
        timeFrame: form.timeframe,
      },
      dataLayerName: "KlevrLandingPages",
    };

    TagManager.initialize(tagManagerArgs);
  }

  override render() {
    const { classes, form, demoForm, isDemo, landing, errors } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h5" className={classes.primaryText}>
          {`Beautiful Home ${
            isDemo ? demoForm.name.split(" ")[0] : form.name.split(" ")[0]
          }!`}
        </Typography>
        <Typography variant="h5" className={classes.primaryText}>
          Your Report Is On Its Way
        </Typography>

        <div style={{ maxWidth: "550px", marginTop: "24px" }}>
          <Divider variant="fullWidth" style={{ marginBottom: "16px" }} />
          <Typography variant="h6" className={classes.primaryText}>
            Have a question about your report?
          </Typography>
          <Button
            className={classes.containedButton}
            variant="contained"
            color="secondary"
            href={`tel:${isDemo ? "" : landing.settings.appPhoneNumber}`}
          >
            Give Us A Call!
          </Button>
          <Typography className={classes.footerText}>
            Understanding how your home stacks up is vitally important!&nbsp;
            <a
              className={classes.link}
              href={`tel:${isDemo ? "" : landing.settings.appPhoneNumber}`}
            >
              Ask us
            </a>
            &nbsp;about getting real-time updates of new listings that are
            similar to yours.
          </Typography>
          <Divider variant="middle" style={{ margin: "16px" }} />
        </div>
        <Grid className={classes.content} container justifyContent="center">
          <Grid item xs={12} md={8}>
            <div className={classes.map}>
              <MapWithMarker
                height="40vh"
                markerLat={isDemo ? demoForm.lat : form.lat}
                markerLng={isDemo ? demoForm.lng : form.lng}
              />
            </div>
            <Typography variant="subtitle1" className={classes.secondaryText}>
              {isDemo ? demoForm.formatted_address : form.formatted_address}
            </Typography>

            <div className={classes.owners}>
              {isDemo || form.zestimate ? (
                <Typography
                  variant="h6"
                  className={classNames(
                    classes.estimatedValues,
                    classes.whiteText
                  )}
                >
                  {isDemo
                    ? parseFloat(demoForm.zestimate).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : parseFloat(form.zestimate).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                  <Typography className={classes.whiteText}>
                    Estimated value
                  </Typography>
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  className={classNames(
                    classes.estimatedValues,
                    classes.darkText
                  )}
                >
                  Sorry, we couldn't find an estimation for your property in our
                  database.
                </Typography>
              )}
            </div>
            <Typography className={classes.description}>
              <LockIcon className={classes.lockIcon} />
              100% FREE. No obligation. Your info is secure and will not be
              shared.
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.seller.form,
  demoForm: state.seller.demoForm,
  step: state.seller.step,
  errors: state.seller.errors,
  landing: state.landings.landing,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, { updateForm, updateStep })(
  withStyles(styles, { withTheme: true })(LandingSuccess)
);
