import React from "react";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import { Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Avatar from "@mui/material/Avatar";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      backgroundColor: "#2695F3",
      padding: theme.spacing(2),
      borderRadius: theme.spacing(),
    },
    header: {
      display: "flex",
      alignItems: "center",
      color: "white",
      marginBottom: theme.spacing(2),
    },
    spacer: {
      flex: 1,
    },
    avatar: {
      width: 24,
      height: 24,
      borderRadius: "50%",
      backgroundColor: "#FFFF80",
    },
    username: {
      fontWeight: "bold",
      marginLeft: theme.spacing(2),
      color: "white",
    },
    star: {
      fontSize: 16,
      marginLeft: theme.spacing(0.5),
      color: "#e6b005",
    },
    description: {
      color: "white",
      textAlign: "left",
    },
  });

interface Props extends WithStyles<typeof styles> {
  name: string;
  testimonial: string;
  alt: string;
  src: any;
}

export default withStyles(styles, { withTheme: true })(
  class ReviewCard extends React.Component<Props> {
    override render() {
      const { classes, name, testimonial, alt, src } = this.props;

      return (
        <div className={classes.root}>
          <div className={classes.header}>
            <Avatar alt={alt} src={src} className={classes.avatar} />
            <Typography className={classes.username}>{name}</Typography>
            <div className={classes.spacer} />
            <div>
              {Array(5)
                .fill(0)
                .map((v, i) => (
                  <StarIcon key={i} className={classes.star} />
                ))}
            </div>
          </div>
          <Typography className={classes.description}>{testimonial}</Typography>
        </div>
      );
    }
  }
);
