import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Button,
} from "@mui/material";
import Loading from "../../common/loading";
import {
  updateForm,
  updateStep,
  updateLeadBuyer,
} from "../../redux/buyer-actions";
import TagManager from "react-gtm-module";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(2),
    },
    primaryText: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
    },
    content: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    selectLabel: {
      color: "white !important",
      fontSize: theme.typography.subtitle1.fontSize,
    },
    selectInput: {
      // marginTop: `${theme.spacing(3)} !important`,
    },
    select: {
      backgroundColor: "white",
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      "&:focus": {
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
      },
    },
    link: {
      textDecoration: "none",
    },
    containedButton: {
      backgroundColor: "#2694f1",
      marginTop: theme.spacing(2),
      // "&:hover": {
      //   backgroundColor: "#2694f1"
      // }
    },
    textButton: {
      color: "white",
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  //
}

class LandingStep2 extends React.Component<any> {
  override componentDidMount() {
    const { form, landing, updateForm } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      fromLandingPageId: landing.id,
      tags: ["buyer"],
      numberBedrooms: form.numberBedrooms || "1",
      numberBathrooms: form.numberBathrooms || "1",
      timeframe: form.timeframe || "Just curious",
      name: "",
      phoneNumber: "",
    };
    updateForm(newForm);

    const tagManagerArgs = {
      gtmId: "GTM-N5HCWHV",
      events: {
        event: "emailCapture",
      },
      dataLayer: {
        emailCapture: true,
        userId: form.userId,
        leadId: form.id,
        tags: form.tags,
        landingPageId: form.fromLandingPageId,
      },
      dataLayerName: "KlevrLandingPages",
    };

    TagManager.initialize(tagManagerArgs);
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    updateForm(newForm);
  };

  handleClick = (e: any) => {
    //e.preventDefault();
    const { form, isDemo, updateStep, updateLeadBuyer } = this.props;

    if (isDemo) {
      updateStep("3");
      return;
    }

    if (
      form.numberBedrooms.length > 0 &&
      form.numberBathrooms.length > 0 &&
      form.timeframe.length > 0
    ) {
      updateLeadBuyer(form).then(() => {
        updateStep("3");
      });
    }
  };

  handleGoBack = (e: any) => {
    const { form, updateStep } = this.props;
    updateStep("1");
  };

  override render() {
    const { classes, form, demoForm, isFetching, isDemo } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.primaryText}>
          Can you specify the details?
        </Typography>
        <Grid className={classes.content} container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="white">
                    Number of Bedrooms
                  </Typography>

                  <Select
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDemo}
                    value={
                      isDemo ? demoForm.numberBedrooms : form.numberBedrooms
                    }
                    input={
                      <Input
                        onChange={this.handleChange}
                        className={classes.selectInput}
                        name="numberBedrooms"
                        id="numberBedrooms"
                        disableUnderline
                      />
                    }
                    displayEmpty
                    name="numberBedrooms"
                  >
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                    <MenuItem value={"5+"}>5+</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="white">
                    Number of Bathrooms
                  </Typography>

                  <Select
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDemo}
                    value={
                      isDemo ? demoForm.numberBathrooms : form.numberBathrooms
                    }
                    input={
                      <Input
                        onChange={this.handleChange}
                        className={classes.selectInput}
                        name="numberBathrooms"
                        id="numberBathrooms"
                        disableUnderline
                      />
                    }
                    displayEmpty
                    name="numberBathrooms"
                  >
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                    <MenuItem value={"5+"}>5+</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="white">
                    Buying In
                  </Typography>

                  <Select
                    classes={{
                      select: classes.select,
                    }}
                    disabled={isDemo}
                    value={isDemo ? demoForm.timeframe : form.timeframe}
                    input={
                      <Input
                        onChange={this.handleChange}
                        className={classes.selectInput}
                        name="timeframe"
                        id="timeframe"
                        disableUnderline
                      />
                    }
                    displayEmpty
                    name="timeframe"
                  >
                    <MenuItem value={"Just curious"}>Just curious</MenuItem>
                    <MenuItem value={"1-3 months"}>1-3 months</MenuItem>
                    <MenuItem value={"3-6 months"}>3-6 months</MenuItem>
                    <MenuItem value={"6-12 months"}>6-12 months</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={11} sm={6} md={4}>
            <Button
              // className={classes.containedButton}
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              disabled={isFetching}
              onClick={this.handleClick}
            >
              {isFetching ? <Loading size="2rem" /> : "Next"}
            </Button>
            <Button
              className={classes.textButton}
              color="primary"
              size="large"
              fullWidth
              disabled={isFetching}
              onClick={this.handleGoBack}
            >
              Go back
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.buyer.form,
  demoForm: state.buyer.demoForm,
  step: state.buyer.step,
  isFetching: state.buyer.isFetching,
  landing: state.landings.landing,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, {
  updateForm,
  updateStep,
  updateLeadBuyer,
})(withStyles(styles, { withTheme: true })(LandingStep2));
