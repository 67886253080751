import {
  INITIALIZE_SELLER_FORM,
  SET_GOOGLE_INFO,
  SET_GOOGLE_INFO_SUCCESS,
  SET_GOOGLE_INFO_FAIL,
  SET_ZILLOW_INFO,
  SET_ZILLOW_INFO_SUCCESS,
  SET_ZILLOW_INFO_FAIL,
  SELLER_FORM_UPDATED,
  SELLER_STEP_UPDATED,
  SELLER_LEAD_CREATING,
  SELLER_LEAD_CREATING_SUCCESS,
  SELLER_LEAD_CREATING_FAIL,
  SELLER_LEAD_UPDATING,
  SELLER_LEAD_UPDATING_SUCCESS,
  SELLER_LEAD_UPDATING_FAIL,
} from "./seller-actions";

import { ReduxAction } from "./common";

interface SellerState {
  form: any;
  demoForm: any;
  errors: any;
  step: string;
  isFetching: boolean;
}

const initialErrors = {
  zillowQuery: false,
};

const initialSellerDemoForm = {
  formatted_address: "3754 Idlewood Ave, Las Vegas, NV 89115, USA",
  rawAddress: "3754 Idlewood Ave, Las Vegas, NV 89115, USA",
  routeAddress: "3754 Idlewood Ave",
  cityAddress: "Las Vegas",
  stateAddress: "NV",
  zipcodeAddress: "89115",
  lat: 36.2290381,
  lng: -115.0927246,
  zestimate: "237870",
  zhigh: "249763",
  zlow: "225977",
  zpid: "59192667",
  numberBedrooms: "1",
  numberBathrooms: "1",
  squareFeet: "0-1000",
  timeframe: "1-3 months",
  tags: ["seller"],
  name: "Demo Lead",
  email: "demo@seller.com",
  phoneNumber: "+17023651234",
};

const initialSellerForm = {
  id: "",
  userId: "",
  fromLandingPageId: "",
  formatted_address: "",
  rawAddress: "",
  routeAddress: "",
  cityAddress: "",
  stateAddress: "",
  zipcodeAddress: "",
  lat: "",
  lng: "",
  zestimate: "", //derived from Zillow
  zhigh: "", //derived from Zillow
  zlow: "", //derived from Zillow
  zpid: "", //derived from Zillow
  numberBedrooms: "",
  numberBathrooms: "",
  squareFeet: "",
  timeframe: "",
  name: "",
  email: "",
  phoneNumber: "",
  tags: ["seller"],
};

const initialState: SellerState = {
  form: initialSellerForm,
  demoForm: initialSellerDemoForm,
  errors: initialErrors,
  step: "1",
  isFetching: false,
};

export const sellerReducer = (
  state: SellerState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;
  switch (type) {
    case INITIALIZE_SELLER_FORM:
      return {
        ...state,
        //form: payload.drip ? payload.drip : initialState.form,
      };

    case SELLER_LEAD_CREATING_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          id: payload.lead.id,
        },
        isFetching: false,
      };

    case SELLER_FORM_UPDATED:
      return {
        ...state,
        form: payload.form,
      };

    case SELLER_STEP_UPDATED:
      return {
        ...state,
        step: payload.step,
      };

    case SET_GOOGLE_INFO_SUCCESS:
      const {
        lat,
        lng,
        rawAddress,
        formatted_address,
        routeAddress,
        cityAddress,
        stateAddress,
        zipcodeAddress,
      } = payload;
      return {
        ...state,
        form: {
          ...state.form,
          lat,
          lng,
          rawAddress,
          formatted_address,
          routeAddress,
          cityAddress,
          stateAddress,
          zipcodeAddress,
        },
        isFetching: false,
      };

    case SET_ZILLOW_INFO_SUCCESS:
      const { zestimate, zpid, zhigh, zlow } = payload;
      return {
        ...state,
        errors: { ...state.errors, zillowQuery: false },
        form: {
          ...state.form,
          zestimate,
          zhigh,
          zlow,
          zpid,
        },
        isFetching: false,
      };

    case SET_ZILLOW_INFO_FAIL:
      return {
        ...state,
        errors: { ...state.errors, zillowQuery: true },
        form: {
          ...state.form,
          zestimate: "",
          zpid: "",
        },
        isFetching: false,
      };

    case SELLER_LEAD_CREATING:
    case SELLER_LEAD_UPDATING:
    case SET_GOOGLE_INFO:
    case SET_ZILLOW_INFO:
      return {
        ...state,
        isFetching: true,
      };

    case SELLER_LEAD_CREATING_FAIL:
    case SELLER_LEAD_UPDATING_SUCCESS:
    case SELLER_LEAD_UPDATING_FAIL:
    case SET_GOOGLE_INFO_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
