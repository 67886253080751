import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import "./App.css";

import CssBaseline from "@mui/material/CssBaseline";
import {
  StyledEngineProvider,
  ThemeProvider,
  Theme,
} from "@mui/material/styles";

import LandingPageOne from "./landing-page-1";
import LandingPageTwo from "./landing-page-2";
import LandingPageThree from "./landing-page-3";
import LandingPageFour from "./landing-page-4";
import LandingPageFive from "./landing-page-5";
import DirectMailOne from "./direct-mail-1";
import theme from "./theme/theme";
import ErrorNotFound from "./common/error-not-found";
import { handleLanding } from "./redux/landings-actions";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const parseSubdomain = (url: string) => {
  const urlParts = url.split(".");
  return urlParts[0];
};

class App extends React.Component<any> {
  override componentDidMount = async () => {
    const { match } = this.props;

    const templateId = match.params.templateId;
    const landingPageId = match.params.landingPageId;
    const confirmationCode = match.params.confirmationCode
      ? match.params.confirmationCode.toUpperCase()
      : "";

    await this.props.handleLanding(
      templateId,
      landingPageId,
      confirmationCode,
      parseSubdomain(window.location.host)
    );
  };

  changeHtmlTitle = (landing: any) => {
    const htmlTitle =
      landing && landing.settings && landing.settings.htmlTitle
        ? landing.settings.htmlTitle
        : "Buy/Sell Real Estate";

    document.title = htmlTitle;
  };

  override render() {
    const { landing, match } = this.props;
    const templateId = parseInt(match.params.templateId);
    const confirmationCode = match.params.confirmationCode
      ? match.params.confirmationCode.toUpperCase()
      : "";
    const subdomain = parseSubdomain(window.location.host);

    this.changeHtmlTitle(landing);

    return (
      <div className="App">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <Switch>
                {templateId === 1 && (
                  <Route render={(props) => <LandingPageOne {...props} />} />
                )}

                {templateId === 2 && (
                  <Route render={(props) => <LandingPageTwo {...props} />} />
                )}

                {templateId === 3 && (
                  <Route render={(props) => <LandingPageThree {...props} />} />
                )}

                {templateId === 4 && (
                  <Route render={(props) => <LandingPageFour {...props} />} />
                )}
                {templateId === 5 && (
                  <Route render={(props) => <LandingPageFive {...props} />} />
                )}

                {confirmationCode && (
                  <Route
                    render={(_) => (
                      <DirectMailOne
                        confirmationCode={confirmationCode}
                        subdomain={subdomain}
                      />
                    )}
                  />
                )}

                <Route component={ErrorNotFound} />
              </Switch>
            </CssBaseline>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  landing: state.landings.landing,
});

export default connect(mapStateToProps, { handleLanding })(App);
