import api from "../api/api";

export const DEMO_ENABLE = "DEMO_ENABLE";
export const DEMO_DISABLE = "DEMO_DISABLE";
export const LANDING_FETCHING = "LANDING_FETCHING";
export const LANDING_FETCHING_SUCCESS = "LANDING_FETCHING_SUCCESS";
export const LANDING_FETCHING_FAIL = "LANDING_FETCHING_FAIL";
export const VERIFY_CONFIRMATIONCODE = "VERIFY_CONFIRMATIONCODE";
export const VERIFY_CONFIRMATIONCODE_SUCCESS =
  "VERIFY_CONFIRMATIONCODE_SUCCESS";
export const VERIFY_CONFIRMATIONCODE_FAIL = "VERIFY_CONFIRMATIONCODE_FAIL";
export const UPDATE_AND_FETCH_CLOUDCMA_REPORT =
  "UPDATE_AND_FETCH_CLOUDCMA_REPORT";
export const UPDATE_AND_FETCH_CLOUDCMA_REPORT_SUCCESS =
  "UPDATE_AND_FETCH_CLOUDCMA_REPORT_SUCCESS";
export const UPDATE_AND_FETCH_CLOUDCMA_REPORT_FAIL =
  "UPDATE_AND_FETCH_CLOUDCMA_REPORT_FAIL";

export const updateLeadAndFetchCloudCMAReport = (
  newFullName: string,
  newEmailAddress: string,
  newPhoneNumber: string,
  confirmationLead: any,
  confirmationUser: any,
  confirmationCode: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: UPDATE_AND_FETCH_CLOUDCMA_REPORT,
        payload: {},
      });

      const res = await api.landings.updateLeadAndFetchCloudCMAReport(
        newFullName,
        newEmailAddress,
        newPhoneNumber,
        confirmationLead,
        confirmationUser,
        confirmationCode
      );

      dispatch({
        type: UPDATE_AND_FETCH_CLOUDCMA_REPORT_SUCCESS,
        payload: {
          cloudCMALink: res.cloudCMALink,
        },
      });

      return true;
    } catch (e: any) {
      //console.log(e.response.data.message);
      dispatch({
        type: UPDATE_AND_FETCH_CLOUDCMA_REPORT_FAIL,
        payload: { errorMessage: e.response.data.message },
      });
      return false;
    }
  };
};

export const verifyConfirmationCode = (
  confirmationCode: string,
  subdomain?: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: VERIFY_CONFIRMATIONCODE,
        payload: {},
      });
      const res = await api.landings.verifyConfirmationCode(
        confirmationCode,
        subdomain
      );
      dispatch({
        type: VERIFY_CONFIRMATIONCODE_SUCCESS,
        payload: {
          confirmationLead: res.lead,
          confirmationUser: res.user,
        },
      });
      return true;
    } catch (e: any) {
      dispatch({
        type: VERIFY_CONFIRMATIONCODE_FAIL,
      });
      return false;
    }
  };
};

const fetchLanding =
  (
    templateId: number,
    landingPageId: number,
    confirmationCode: string,
    subdomain: string
  ) =>
  (dispatch: Function) => {
    dispatch({
      type: LANDING_FETCHING,
    });

    api.landings
      .fetchOne(landingPageId, confirmationCode, subdomain)
      .then((res: any) => {
        const landing = res.landing;
        const landingUser = res.landingUser;
        const landingPageAccessToken = res.landingPageAccessToken;

        //check if fetched landing settings match with seller/buyer profile
        if (landing.settings.templateCode === templateId) {
          // create a landingPageView
          api.landingPageViews.createOne(landingPageId);

          dispatch({
            type: LANDING_FETCHING_SUCCESS,
            payload: {
              landing,
              landingUser,
            },
          });
        } else {
          dispatch({
            type: LANDING_FETCHING_FAIL,
          });
        }
      })
      .catch((err: any) =>
        dispatch({
          type: LANDING_FETCHING_FAIL,
        })
      );
  };

export const handleLanding =
  (
    templateId: string,
    landingPageId: string,
    confirmationCode: string,
    subdomain: string
  ) =>
  (dispatch: Function) => {
    if (landingPageId === "demo") {
      dispatch({
        type: DEMO_ENABLE,
      });
    } else {
      dispatch({
        type: DEMO_DISABLE,
      });
    }

    let templateIdAsIntAsInteger = parseInt(templateId);
    let landingPageIdAsInteger = parseInt(landingPageId);

    if (
      isNaN(templateIdAsIntAsInteger) ||
      isNaN(landingPageIdAsInteger) ||
      (templateIdAsIntAsInteger !== 1 &&
        templateIdAsIntAsInteger !== 2 &&
        templateIdAsIntAsInteger !== 3 &&
        templateIdAsIntAsInteger !== 4 &&
        templateIdAsIntAsInteger !== 5)
    ) {
      dispatch({
        type: LANDING_FETCHING_FAIL,
      });
    } else {
      dispatch(
        fetchLanding(
          templateIdAsIntAsInteger,
          landingPageIdAsInteger,
          confirmationCode,
          subdomain
        )
      );
    }
  };
