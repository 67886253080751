import React from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import { Typography, Grid, Button } from "@mui/material";
import TagManager from "react-gtm-module";
import Loading from "../../common/loading";
import { colors } from "../../common/colors";

import NumberOfBedrooms from "../common/number-of-bedrooms";
import NumberOfBathrooms from "../common/number-of-bathrooms";
import SquareFeet from "../common/square-feet";

import {
  updateForm,
  updateStep,
  updateLeadSeller,
} from "../../redux/seller-actions";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
      // paddingTop: theme.spacing(8),
      // paddingBottom: theme.spacing(2),
      marginBottom: "15vh",
      maxWidth: "500px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    primaryText: {
      color: colors.darkBlue,
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "40px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "3vh",
      },
    },
    goBackText: {
      color: colors.lightGrey,
      // opacity: 0.5,
      textAlign: "center",
    },
    content: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    containedButton: {
      backgroundColor: colors.klevrGreen,
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: colors.klevrGreenDark,
      },
      maxWidth: "90%",
    },
    textButton: {
      color: "white",
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    inputWrapper: {
      marginBottom: "16px",
    },
  });

interface Props extends WithStyles<typeof styles> {
  successRoute: string;
}

class LandingStep4 extends React.Component<any> {
  override componentDidMount() {
    const { form, landing, updateForm } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      name: form.name || "",
      email: form.email || "",
      fromLandingPageId: landing.id,
      tags: ["seller"],
      timeframe: form.timeframe || "1-3 months",
      phoneNumber: form.phoneNumber || "",
    };
    updateForm(newForm);

    const tagManagerArgs = {
      gtmId: "GTM-N5HCWHV",
      events: {
        event: "partialLead",
      },
      dataLayer: {
        partialLead: true,
        userId: form.userId,
        leadId: form.id,
        landingPageId: form.fromLandingPageId,
        zipCode: form.zipcodeAddress,
        city: form.cityAddress,
        state: form.stateAddress,
        tags: form.tags,
        propertyEstimate: form.zestimate,
      },
      dataLayerName: "KlevrLandingPages",
    };

    TagManager.initialize(tagManagerArgs);
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    updateForm(newForm);
  };

  handleCalculateValue = (e: any) => {
    //e.preventDefault();
    const { form, isDemo, updateForm, updateStep, updateLeadSeller } =
      this.props;

    if (isDemo) {
      updateStep("5");
      return;
    }

    // let phoneNumber = formatPhoneNumberIntoE164(form.phoneNumber);

    if (
      form.numberBedrooms.length > 0 &&
      form.numberBathrooms.length > 0 &&
      form.squareFeet.length > 0
      // phoneNumber &&
      // form.timeframe.length > 0 &&
      // isEmail(form.email) &&
      // form.name.length > 0
    ) {
      // let newForm = {
      //   ...form,
      //   phoneNumber,
      // };
      // updateForm(newForm);

      updateLeadSeller(form).then(() => {
        updateStep("5");
      });
    }
  };

  handleGoBack = (e: any) => {
    const { updateStep } = this.props;
    updateStep("3");
  };

  override render() {
    const { classes, form, demoForm, landing, isFetching, isDemo } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.primaryText}>
          We just need a few more details...
        </Typography>

        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <NumberOfBedrooms
              className={classes.inputWrapper}
              isDemo={isDemo}
              demoForm={demoForm}
              form={form}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <NumberOfBathrooms
              className={classes.inputWrapper}
              isDemo={isDemo}
              demoForm={demoForm}
              form={form}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SquareFeet
              className={classes.inputWrapper}
              isDemo={isDemo}
              demoForm={demoForm}
              form={form}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={8} sm={6}>
            <Button
              // className={classes.containedButton}
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              disabled={isFetching}
              onClick={this.handleCalculateValue}
            >
              {isFetching ? <Loading size="1rem" /> : "Get My CMA"}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.seller.form,
  demoForm: state.seller.demoForm,
  step: state.seller.step,
  isFetching: state.seller.isFetching,
  landing: state.landings.landing,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, {
  updateForm,
  updateStep,
  updateLeadSeller,
})(withStyles(styles, { withTheme: true })(LandingStep4));
