import React from "react";
import Autocomplete from "react-google-autocomplete";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";

import { Button, Grid, Typography } from "@mui/material";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import klevrTestimonialF from "../../common/avatars/klevrTestimonialF.jpg";
import klevrTestimonialM from "../../common/avatars/klevrTestimonialM.jpg";
import Loading from "../../common/loading";
import ReviewCard from "../../common/review";

import { getGeocode, updateForm, updateStep } from "../../redux/seller-actions";

const styles = (theme: Theme) =>
  createStyles({
    main: {},
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: theme.spacing(2),
    },
    headerFont: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
    },
    primaryText: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "3rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
      },
    },
    secondaryText: {
      color: "white",
      // opacity: 0.5,
      marginTop: theme.spacing(4),
      textAlign: "center",
    },
    actionbar: {
      height: 60,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    addressInput: {
      height: 60,
      backgroundColor: "white",
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    searchButton: {
      flexBasis: "auto",
    },
    action: {
      height: "100%",
      display: "flex",
      // minWidth: "150px",
      // maxWidth: "190px",
      // justifyContent: "flex-end",
      alignItems: "center",
      backgroundColor: "white",
      fontWeight: "bold",
      color: "#2694f1",
      textDecoration: "none",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
      "&:hover": {
        backgroundColor: "#2694f1",
        color: "white",
      },
    },
    descriptionText: {
      color: "white",
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    spacer: {
      flex: 1,
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10vh",
      },
    },
    reviewSpacer: {
      [theme.breakpoints.up("md")]: {
        marginTop: "80px",
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  //
}

class LandingStep1 extends React.Component<any> {
  override componentDidMount() {
    const { form, landing, updateForm, isDemo } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      fromLandingPageId: landing.id,
      tags: ["seller"],
      formatted_address: "",
      routeAddress: "",
      cityAddress: "",
      stateAddress: "",
      zipcodeAddress: "",
      lat: "",
      lng: "",
      zestimate: "",
      zhigh: "",
      zlow: "",
      zpid: "",
      name: "",
      email: "",
      numberBedrooms: "",
      numberBathrooms: "",
      squareFeet: "",
      timeframe: "",
      phoneNumber: "",
    };
    updateForm(newForm);

    if (isDemo === true) {
      return;
    } else {
      const tagManagerArgs = {
        gtmId: "GTM-N5HCWHV",
        events: {
          event: "initialPageView",
        },
        dataLayer: {
          initialPageView: true,
          userId: landing.userId,
          landingPageId: landing.id,
        },
        dataLayerName: "KlevrLandingPages",
      };
      TagManager.initialize(tagManagerArgs);
    }
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };
    updateForm(newForm);
  };

  handlePlaceSelected = (place: any) => {
    const { form, updateForm, updateStep, getGeocode } = this.props;

    let newForm = {
      ...form,
      rawAddress: place.formatted_address,
    };
    updateForm(newForm);

    getGeocode(place.formatted_address).finally(() => {
      updateStep("2");
    });
  };

  handleClick = (e: any) => {
    const { form, isDemo, updateStep, getGeocode } = this.props;

    if (isDemo) {
      updateStep("2");
      return;
    }

    if (form.rawAddress && form.rawAddress.length > 0) {
      getGeocode(form.rawAddress).finally(() => {
        updateStep("2");
      });
    }
  };

  override render() {
    const {
      classes,
      form,
      demoForm,
      landing,
      isFetching,
      isDemo,
      name,
      src,
      testimonial,
      altTag,
      imageUrl,
    } = this.props;

    return (
      // <main className={classes.main}>
      // </main>
      <div className={classes.root}>
        <Typography className={classes.primaryText}>
          {landing && landing.settings && landing.settings.heading
            ? landing.settings.heading
            : "What's your home worth?"}
        </Typography>
        <Typography variant="h6" className={classes.secondaryText}>
          {landing && landing.settings && landing.settings.subheading
            ? landing.settings.subheading
            : "Find out in 3 easy steps..."}
        </Typography>
        <Grid className={classes.actionbar} container justifyContent="center">
          <Grid item xs={10} md={8}>
            <Autocomplete
              style={{
                height: "100%",
                width: "100%",
                border: "0",
                outline: "none", //remove highlight arount input box
                backgroundColor: "white",
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
                fontFamily: "Arial",
                fontSize: "90%",
                paddingTop: "1px",
                paddingBottom: "1px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
              options={{
                componentRestrictions: { country: "us" },
                types: ["address"],
              }}
              disabled={isDemo}
              defaultValue={
                isDemo ? demoForm.rawAddress : form.rawAddress || ""
              }
              placeholder="123 Main Street, Los Angeles, CA, USA"
              onPlaceSelected={this.handlePlaceSelected}
              name="rawAddress"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={2} className={classes.searchButton}>
            <Button
              className={classes.action}
              disabled={isFetching}
              onClick={this.handleClick}
            >
              {isFetching ? (
                <Loading size="1rem" />
              ) : (
                <SearchIcon fontSize="large" />
              )}
            </Button>
          </Grid>
        </Grid>
        <Typography className={classes.descriptionText}>
          ― 97% more accurate than Zillow and 100% free ―
        </Typography>

        <div className={classes.spacer} />

        <Grid
          className={classes.reviewSpacer}
          container
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <ReviewCard
              name="Nicole Lee"
              src={klevrTestimonialF}
              alt="Agent testimonial"
              testimonial="I was thinking about selling and decided to check my home's actual value. Turned out it was worth a few thousand more than I thought!"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReviewCard
              name="Brian Sanders"
              src={klevrTestimonialM}
              alt="Agent testimonial"
              testimonial="Amazingly helpful service and top notch agent! Can't thank you enough for the market analysis on my home."
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.seller.form,
  demoForm: state.seller.demoForm,
  step: state.seller.step,
  isFetching: state.seller.isFetching,
  landing: state.landings.landing,
  landingUser: state.landings.landingUser,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, { updateForm, updateStep, getGeocode })(
  withStyles(styles, { withTheme: true })(LandingStep1)
);
