import axios from "axios";
import Geocode from "react-geocode";

export const googleApiKey = "AIzaSyC83yJWR5xU2X9jSFhpWwgAka0wRzg1ARk";

Geocode.setApiKey(googleApiKey);
//enable this to have a complete log of Geocode result
//Geocode.enableDebug();

let landingPageAccessToken = "";

const mapResponseToPayload = (res: any) => res.data;

export default {
  landings: {
    fetchOne: (
      landingPageId: number,
      confirmationCode?: string,
      subdomain?: string
    ) => {
      return axios
        .get(`/api/landing-pages/public`, {
          params: { landingPageId, confirmationCode, subdomain },
        })
        .then((res) => {
          landingPageAccessToken = res.data.landingPageAccessToken;
          return res.data;
        });
    },

    verifyConfirmationCode: (confirmationCode: string, subdomain?: string) =>
      axios
        .get(`/api/landing-pages/verify_confirmation_code`, {
          params: { confirmationCode, subdomain },
        })
        .then(mapResponseToPayload),

    updateLeadAndFetchCloudCMAReport: (
      newFullName: string,
      newEmailAddress: string,
      newPhoneNumber: string,
      confirmationLead: any,
      confirmationUser: any,
      confirmationCode: string
    ) =>
      axios
        .put(`/api/landing-pages/cloudcma`, {
          newFullName,
          newEmailAddress,
          newPhoneNumber,
          confirmationLead,
          confirmationUser,
          confirmationCode,
        })
        .then(mapResponseToPayload),
  },

  landingPageViews: {
    createOne: (landingPageId: number) => {
      return axios.post("/api/landing-pages/views", { landingPageId });
    },
  },

  leads: {
    createOne: (leadForm: any) => {
      return axios
        .post("/api/leads", { leadForm, landingPageAccessToken })
        .then((res: any) => {
          return res.data;
        });
    },
    updateOne: (leadForm: any) => {
      return axios
        .put("/api/leads", { leadForm, landingPageAccessToken })
        .then((res: any) => {
          return res.data;
        });
    },
  },

  zillow: {
    GetSearchResults: (params: any) => {
      return axios.post("/api/estimates", { params }).then((res: any) => {
        if (res.data.message.code === "0") {
          return res.data.response.results.result;
        } else {
          throw new Error(res.data.message.text);
        }
      });
    },
  },

  googleMaps: {
    geocode: (address: string) =>
      new Promise((resolve, reject) =>
        Geocode.fromAddress(address).then(
          (response: any) => {
            resolve(response.results[0]);
          },
          (error: any) => {
            reject(error);
          }
        )
      ),
  },

  mockup: {
    mock: (input: any) =>
      new Promise((resolve, reject) => {
        console.log(input);
        let error = false;
        if (!error) {
          let res = {
            success: true,
            messsage: "everything fine",
          };
          resolve(res);
        } else {
          //let err = new Error("Wrong username/password");
          let err = {
            success: false,
            message: "awful error",
          };
          reject(err);
        }
      }),
  },
};
