import React, { Suspense, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { withStyles } from "@mui/styles";

import { createStyles } from "@mui/material";

import { isValidConfirmationCode } from "../common/utils";
import { verifyConfirmationCode } from "../redux/landings-actions";

const DirectMailOneErrorWrongCode = React.lazy(
  () => import("./direct-mail-1-error-wrong-code")
);
const DirectMailOneVerifyingCode = React.lazy(
  () => import("./direct-mail-1-verifying-code")
);
const DirectMailOneFetchingReport = React.lazy(
  () => import("./direct-mail-1-fetching-report")
);
const DirectMailOneErrorNoReport = React.lazy(
  () => import("./direct-mail-1-error-no-report")
);
const DirectMailOneForm = React.lazy(() => import("./direct-mail-1-form"));
const DirectMailOneSuccess = React.lazy(
  () => import("./direct-mail-1-success")
);

const styles = () =>
  createStyles({
    root: {
      paddingTop: "100px",
      textAlign: "center",
    },
  });

const loading = () => (
  // the following line is only a sample using Bootstrap4 classes
  <div className="animated fadeIn pt-3 text-center">loading...</div>
);

const renderPage = (props: any) => {
  const {
    classe,
    confirmationCode,
    subdomain,
    isVerifyingConfirmationCode,
    confirmationCodeFail,
    confirmationLead,
    confirmationUser,
    isFetchingCloudCMAReport,
    cloudCMALink,
    errorFetchingCloudCMAReport,
  } = props;

  if (!isValidConfirmationCode(confirmationCode) || confirmationCodeFail)
    return (
      <Suspense fallback={loading()}>
        <DirectMailOneErrorWrongCode confirmationUser={confirmationUser} />
      </Suspense>
    );

  if (isVerifyingConfirmationCode)
    return (
      <Suspense fallback={loading()}>
        <DirectMailOneVerifyingCode />
      </Suspense>
    );

  if (
    confirmationLead &&
    confirmationUser &&
    !isFetchingCloudCMAReport &&
    !cloudCMALink &&
    !errorFetchingCloudCMAReport
  )
    return (
      <Suspense fallback={loading()}>
        <DirectMailOneForm
          confirmationLead={confirmationLead}
          confirmationUser={confirmationUser}
          confirmationCode={confirmationCode}
        />
      </Suspense>
    );

  if (isFetchingCloudCMAReport || cloudCMALink)
    return (
      <Suspense fallback={loading()}>
        <DirectMailOneFetchingReport
          isFetchingCloudCMAReport={isFetchingCloudCMAReport}
          cloudCMALink={cloudCMALink}
          confirmationUser={confirmationUser}
        />
      </Suspense>
    );

  if (errorFetchingCloudCMAReport)
    return (
      <Suspense fallback={loading()}>
        <DirectMailOneErrorNoReport confirmationUser={confirmationUser} />
      </Suspense>
    );

  // Various forms of redirecting weren't working
  //
  // if (cloudCMALink) {
  //   console.log(cloudCMALink);
  //   window.location.replace(cloudCMALink);
  //
  //   // return (
  //   //   <Route
  //   //     path="/external"
  //   //     component={() => {
  //   //       window.location.href = cloudCMALink;
  //   //       return null;
  //   //     }}
  //   //   />
  //   // );
  // }

  return (
    <Suspense fallback={loading()}>
      <DirectMailOneErrorWrongCode />
    </Suspense>
  );
};

const DirectMailOne = (props: any) => {
  const {
    classe,
    confirmationCode,
    subdomain,
    isVerifyingConfirmationCode,
    confirmationCodeFail,
    confirmationLead,
    confirmationUser,
    isFetchingCloudCMAReport,
    cloudCMALink,
    errorFetchingCloudCMAReport,
  } = props;

  useEffect(() => {
    if (isValidConfirmationCode(confirmationCode)) {
      props.verifyConfirmationCode(confirmationCode, subdomain);
    } else {
      console.log("wrong confirmation code");
    }
  }, [confirmationCode, subdomain]);

  return <div>{renderPage(props)}</div>;
};

const mapStateToProps = (state: any) => ({
  isVerifyingConfirmationCode: state.landings.isVerifyingConfirmationCode,
  confirmationCodeFail: state.landings.confirmationCodeFail,
  confirmationLead: state.landings.confirmationLead,
  confirmationUser: state.landings.confirmationUser,
  isFetchingCloudCMAReport: state.landings.isFetchingCloudCMAReport,
  cloudCMALink: state.landings.cloudCMALink,
  errorFetchingCloudCMAReport: state.landings.errorFetchingCloudCMAReport,
});

export default connect(mapStateToProps, {
  verifyConfirmationCode,
})(withStyles(styles, { withTheme: true })(DirectMailOne));
