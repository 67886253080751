import {
  INITIALIZE_BUYER_FORM,
  BUYER_FORM_UPDATED,
  BUYER_STEP_UPDATED,
  BUYER_LEAD_CREATING,
  BUYER_LEAD_CREATING_SUCCESS,
  BUYER_LEAD_CREATING_FAIL,
  BUYER_LEAD_UPDATING,
  BUYER_LEAD_UPDATING_SUCCESS,
  BUYER_LEAD_UPDATING_FAIL,
} from "./buyer-actions";

import { ReduxAction } from "./common";
//import { LandingInterface } from "./common";

interface BuyerState {
  form: any;
  demoForm: any;
  step: string;
  isFetching: boolean;
}

const initialBuyerDemoForm = {
  email: "demo@demo.com",
  numberBedrooms: "1",
  numberBathrooms: "1",
  timeframe: "1-3 months",
  name: "Demo",
  phoneNumber: "+15555551234",
};

const initialBuyerForm = {
  id: "",
  userId: "",
  fromLandingPageId: "",
  email: "",
  numberBedrooms: "",
  numberBathrooms: "",
  timeframe: "",
  name: "",
  phoneNumber: "",
  tags: ["buyer"],
};

const initialState: BuyerState = {
  form: initialBuyerForm,
  demoForm: initialBuyerDemoForm,
  step: "1",
  isFetching: false,
};

export const buyerReducer = (
  state: BuyerState = initialState,
  action: ReduxAction
) => {
  const { payload, type } = action;
  switch (type) {
    case INITIALIZE_BUYER_FORM:
      return {
        ...state,
      };

    case BUYER_FORM_UPDATED:
      return {
        ...state,
        form: payload.form,
      };

    case BUYER_STEP_UPDATED:
      return {
        ...state,
        step: payload.step,
      };
    case BUYER_LEAD_CREATING_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          id: payload.lead.id,
        },
        isFetching: false,
      };

    case BUYER_LEAD_CREATING:
    case BUYER_LEAD_UPDATING:
      return {
        ...state,
        isFetching: true,
      };

    case BUYER_LEAD_CREATING_FAIL:
    case BUYER_LEAD_UPDATING_SUCCESS:
    case BUYER_LEAD_UPDATING_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
