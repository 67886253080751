export const colors = {
  green: "#00d88e",
  red: "#ff0000",
  error: "#f44336",
  play: "#00d88e",
  replay: "#2196f3",
  pause: "#FBB13A",
  stop: "#ff0000",
  completed: "#2944d2",
  addToDrip: "#2944d2",
  addToDripBackground: "rgba(41,68,210,.2)",
  bulkExportBackground: "rgba(0,216,142,.2)",
  bulkImport: "#FBB13A",
  bulkImportBackground: "rgba(251,177,58,.2)",
  buttonBorder: "rgba(0,0,0,.23)",
  email: "#f44336",
  sms: "#00d88e",
  smsDark: "#37a882",
  notes: "#ff9800",
  white: "#FFFFFF",
  black: "#212121",
  lightGrey: "#eeeeee",
  darkGrey: "#979797",
  darkBlue: "#2C3340",
  lightBlue: "#2882FC",
  klevrGreen: "#00d88e",
  klevrGreenDark: "#03c07f",
  reviewStars: "#ffc200",
  likelyLightBlue: "#2682c4",
  likelyLightBlueBackground: "#e0edf7",
  likelyDarkBlue: "#225eab",
  remaxDarkBlue: "#002d70",
  remaxLightBlue: "#003ea5",
};
