import React from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "../../theme/react-phone-input-2.css";
import Loading from "../../common/loading";
import { formatPhoneNumberIntoE164 } from "../../common/utils";
import { colors } from "../../common/colors";

import SellingInInput from "../common/selling-in-input";

import {
  updateForm,
  updateStep,
  updateLeadSeller,
} from "../../redux/seller-actions";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
      // paddingTop: theme.spacing(8),
      // paddingBottom: theme.spacing(2),
      marginBottom: "15vh",
      maxWidth: "500px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    primaryText: {
      color: colors.darkBlue,
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "16px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "3vh",
      },
    },

    secondaryText: {
      color: colors.darkBlue,
      textAlign: "center",
      fontStyle: "italic",
      marginBottom: "40px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.5vh",
      },
    },

    containedButton: {
      backgroundColor: colors.klevrGreen,
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: colors.klevrGreenDark,
      },
      maxWidth: "90%",
    },
  });

interface Props extends WithStyles<typeof styles> {
  successRoute: string;
}

class LandingStep5 extends React.Component<any> {
  override componentDidMount() {
    const { form, landing, updateForm } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      name: form.name || "",
      email: form.email || "",
      fromLandingPageId: landing.id,
      tags: ["seller"],
      timeframe: form.timeframe || "1-3 months",
      phoneNumber: form.phoneNumber || "",
    };
    updateForm(newForm);
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    updateForm(newForm);
  };

  setPhoneNumber = (value: any, country: any, e: any, formattedValue: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      phoneNumber: "+" + value,
    };

    updateForm(newForm);
  };

  handleCalculateValue = (e: any) => {
    //e.preventDefault();
    const { form, isDemo, updateForm, updateStep, updateLeadSeller } =
      this.props;

    if (isDemo) {
      updateStep("success");
      return;
    }

    let phoneNumber = formatPhoneNumberIntoE164(form.phoneNumber);

    if (
      phoneNumber &&
      form.timeframe.length > 0
      // isEmail(form.email) &&
      // form.name.length > 0
    ) {
      let newForm = {
        ...form,
        phoneNumber,
      };
      updateForm(newForm);

      updateLeadSeller(newForm).then(() => {
        updateStep("success");
      });
    }
  };

  handleGoBack = (e: any) => {
    const { form, updateStep } = this.props;
    updateStep("4");
  };

  override render() {
    const { classes, form, demoForm, landing, isFetching, isDemo } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.primaryText}>
          Take your report on the Go with Mobile Delivery!
        </Typography>
        <Typography variant="h6" className={classes.secondaryText}>
          We'll Text Your Link When It's Ready!
        </Typography>

        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <SellingInInput
              isDemo={isDemo}
              demoForm={demoForm}
              form={form}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" align="left" color="primary">
                    Phone
                  </Typography>
                  <PhoneInput
                    inputProps={{
                      name: "phoneNumber",
                      label: "phoneNumber",
                      // required: true,
                    }}
                    inputStyle={{
                      background: "#eeeeee",
                      width: "100%",
                      borderRadius: "4px",
                      height: "48px",
                    }}
                    // specialLabel={"Phone Number"}
                    country={"us"} // initialcountry (in some cases behaves weirdly)
                    onlyCountries={["au", "ca", "it", "mx", "us", "es"]}
                    countryCodeEditable={false}
                    disableDropdown={true}
                    placeholder={"For verification purposes only"}
                    disabled={isDemo}
                    value={
                      isDemo ? demoForm.phoneNumber : form.phoneNumber || ""
                    }
                    onChange={(value, country, e, formattedValue) =>
                      this.setPhoneNumber(value, country, e, formattedValue)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8} sm={6}>
            <Button
              // className={classes.containedButton}
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              disabled={isFetching}
              onClick={this.handleCalculateValue}
            >
              {isFetching ? <Loading size="1rem" /> : "Get my CMA"}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.seller.form,
  demoForm: state.seller.demoForm,
  step: state.seller.step,
  isFetching: state.seller.isFetching,
  landing: state.landings.landing,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, {
  updateForm,
  updateStep,
  updateLeadSeller,
})(withStyles(styles, { withTheme: true })(LandingStep5));
