import React from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import { Typography, Grid, Input, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import MapWithMarker from "../common/map-with-marker";
import { colors } from "../../common/colors";
import Loading from "../../common/loading";

import {
  updateForm,
  updateStep,
  updateLeadSeller,
  createLeadSeller,
  getGeocode,
  getEstimate,
} from "../../redux/seller-actions";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
    primaryText: {
      color: colors.darkBlue,
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "3rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
      },
    },
    secondaryText: {
      color: colors.darkBlue,
      opacity: 0.5,
      marginTop: theme.spacing(4),
      textAlign: "center",
    },
    goBackText: {
      color: colors.darkBlue,
      marginTop: "8px",
      // opacity: 0.5,
      textAlign: "center",
    },
    content: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    mapRow: {
      //height: 160,
      height: "30vh",
      backgroundColor: theme.palette.grey[400],
      borderRadius: theme.spacing(0.5),
    },
    inputRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: -30,
    },
    addressInput: {
      height: 48,
      backgroundColor: colors.lightGrey,
      textAlign: "center",
      //borderRadius: theme.spacing(.5),
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    containedButton: {
      backgroundColor: colors.klevrGreen,
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: colors.klevrGreenDark,
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  //
}

class LandingStep2 extends React.Component<any> {
  override componentDidMount() {
    const { form, landing, updateForm } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      fromLandingPageId: landing.id,
      tags: ["seller"],
      zestimate: "",
      zhigh: "",
      zlow: "",
      zpid: "",
      name: "",
      email: "",
      numberBedrooms: "",
      numberBathrooms: "",
      squareFeet: "",
      timeframe: "",
      phoneNumber: "",
    };
    updateForm(newForm);
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    updateForm(newForm);
  };

  handleEdit = (e: any) => {
    const { form, isDemo, getGeocode } = this.props;

    if (isDemo) return;

    const recreatedAddress =
      form.routeAddress +
      ", " +
      form.cityAddress +
      ", " +
      form.stateAddress +
      ", " +
      form.zipcodeAddress;

    getGeocode(recreatedAddress);
  };

  handleCalculateValue = async (e: any) => {
    //e.preventDefault();
    const {
      form,
      isDemo,
      updateStep,
      updateLeadSeller,
      createLeadSeller,
      getEstimate,
    } = this.props;

    if (isDemo) {
      updateStep("3");
      return;
    }

    if (
      form.routeAddress &&
      form.routeAddress.length > 0 &&
      form.cityAddress &&
      form.cityAddress.length > 0 &&
      form.stateAddress &&
      form.stateAddress.length > 0 &&
      form.zipcodeAddress &&
      form.zipcodeAddress.length > 0
    ) {
      const params = {
        routeAddress: form.routeAddress,
        cityAddress: form.cityAddress,
        stateAddress: form.stateAddress,
        zipcodeAddress: form.zipcodeAddress,
      };

      let newForm = form;
      newForm.zestimate = "";
      newForm.zhigh = "";
      newForm.zlow = "";
      newForm.zpid = "";
      getEstimate(params)
        .then((zillowResponse: any) => {
          let zestimate = zillowResponse ? zillowResponse.zestimate || "" : "";
          let zhigh = zillowResponse ? zillowResponse.zhigh || "" : "";
          let zlow = zillowResponse ? zillowResponse.zlow || "" : "";
          let zpid = zillowResponse ? zillowResponse.zpid || "" : "";

          newForm = { ...form, zestimate, zhigh, zlow, zpid };
        })
        .finally(() => {
          if (!newForm.id) {
            createLeadSeller(newForm).then(() => {
              updateStep("3");
            });
          } else {
            updateLeadSeller(newForm).then(() => {
              updateStep("3");
            });
          }
        });
    }
  };

  handleGoBack = (e: any) => {
    const { updateStep } = this.props;
    updateStep("1");
  };

  override render() {
    const { classes, form, demoForm, landing, isFetching, isDemo } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h3" className={classes.primaryText}>
          Is this the correct address?
        </Typography>
        <Typography variant="h6" className={classes.secondaryText}>
          If the address is wrong, tap go back.
        </Typography>

        <Grid className={classes.content} container justifyContent="center">
          <Grid className={classes.mapRow} item xs={12} md={10} lg={8}>
            {/* If height is modified it should be modified on classes.mapRow too */}
            <MapWithMarker
              height="30vh"
              markerLat={isDemo ? demoForm.lat : form.lat}
              markerLng={isDemo ? demoForm.lng : form.lng}
            />
          </Grid>

          <Grid className={classes.inputRow} item xs={11} md={9} lg={7}>
            <Input
              disabled
              classes={{ input: classes.addressInput }}
              placeholder="Type your address"
              fullWidth
              disableUnderline
              //value="123 Main Street, Los Angeles, CA, USA"
              name="rawAddress"
              value={
                isDemo
                  ? demoForm.formatted_address
                  : form.formatted_address || ""
              }
              onChange={this.handleChange}
              // error={errors.htmlTitle.length > 0}
              // helperText={errors.htmlTitle.length ? errors.htmlTitle : ""}
            />
          </Grid>
        </Grid>
        <Grid className={classes.content} container justifyContent="center">
          <Grid item xs={12} sm={5} md={3} lg={3}>
            <Button
              // className={classes.containedButton}
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              disabled={isFetching}
              onClick={this.handleCalculateValue}
            >
              {isFetching ? <Loading size="1rem" /> : "Calculate my value"}
              <ArrowForwardIcon />
            </Button>
            <Button
              className={classes.goBackText}
              disabled={isFetching}
              onClick={this.handleGoBack}
            >
              Go back
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.seller.form,
  demoForm: state.seller.demoForm,
  step: state.seller.step,
  isFetching: state.seller.isFetching,
  landing: state.landings.landing,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, {
  updateForm,
  updateStep,
  updateLeadSeller,
  createLeadSeller,
  getGeocode,
  getEstimate,
})(withStyles(styles, { withTheme: true })(LandingStep2));
