import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import { Typography, Grid, Button } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import SecurityIcon from "@mui/icons-material/Security";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";

import { updateForm, updateStep } from "../redux/seller-actions";
import TagManager from "react-gtm-module";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    primaryText: {
      marginTop: "10vh",
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
    },
    secondaryText: {
      color: "white",
      opacity: 0.5,
      fontWeight: 500,
      marginTop: theme.spacing(2),
      textAlign: "center",
    },
    whiteText: {
      color: "white",
      textAlign: "center",
    },
    greyText: {
      color: "rgba(255, 255, 255, 0.5)",
      textAlign: "center",
    },
    orangeText: {
      color: "#FFB107",
      textAlign: "center",
    },
    footerText: {
      color: "rgba(255, 255, 255, 0.5)",
      textAlign: "center",
    },
    link: {
      color: "#04D263",
    },
    description: {
      color: "#00d88e",
      textAlign: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    lockIcon: {
      fontSize: 14,
      marginRight: theme.spacing(0.5),
    },
    containedButton: {
      width: 240,
      color: "white",
      backgroundColor: "#da4526",
      marginTop: "15vh",
      "&:hover": {
        backgroundColor: "#c53d20",
      },
    },
    spacer: {
      height: "20vh",
    },
  });

interface Props extends WithStyles<typeof styles> {
  t: any;
  isDemo: any;
  landing: any;
  form: any;
}

class LandingSuccess extends React.Component<Props> {
  override componentDidMount() {
    const { form } = this.props;
    const tagManagerArgs = {
      gtmId: "GTM-N5HCWHV",
      events: {
        event: "fullLead",
      },
      dataLayer: {
        fullLead: true,
        userId: form.userId,
        leadId: form.id,
        landingPageId: form.fromLandingPageId,
        tags: form.tags,
        bedrooms: form.numberBedrooms,
        bathrooms: form.numberBathrooms,
        timeFrame: form.timeframe,
      },
      dataLayerName: "KlevrLandingPages",
    };

    TagManager.initialize(tagManagerArgs);
  }

  override render() {
    const { classes, isDemo, landing } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h5" className={classes.primaryText}>
          Your list has been sent!
        </Typography>
        <Typography className={classes.description}>
          <LockIcon className={classes.lockIcon} />
          100% FREE. No obligation. Your info is secure and will not be shared.
        </Typography>
        <Button
          className={classes.containedButton}
          variant={"contained"}
          href={`tel:${isDemo ? "" : landing.settings.appPhoneNumber}`}
        >
          Give Us A Call!
        </Button>
        <div className={classes.spacer} />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isDemo: state.landings.isDemo,
  landing: state.landings.landing,
  form: state.buyer.form,
});

export default connect(mapStateToProps, { updateForm, updateStep })(
  withStyles(styles, { withTheme: true })(LandingSuccess)
);
