import React from "react";
import {
  Grid,
  FormControl,
  Typography,
  InputLabel,
  Select,
  Input,
  MenuItem,
} from "@mui/material";

import { Theme, createStyles } from "@mui/material";

const defaultStyles = (theme: Theme) =>
  createStyles({
    selectLabel: {
      color: "white !important",
      fontSize: theme.typography.subtitle1.fontSize,
    },
    selectInput: {
      marginTop: `${theme.spacing(3)} !important`,
    },
    select: {
      backgroundColor: "white",
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      "&:focus": {
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
      },
    },
  });

const SquareFeet = (props: any) => {
  const { classes, isDemo, demoForm, form, onChange } = props;

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <Typography variant="subtitle1" align="left" color="primary">
          Square Feet
        </Typography>
        <Select
          classes={{
            select: classes.select,
          }}
          disabled={isDemo}
          value={isDemo ? demoForm.squareFeet : form.squareFeet}
          input={
            <Input
              onChange={onChange}
              className={classes.selectInput}
              name="squareFeet"
              id="squareFeet"
              disableUnderline
            />
          }
          displayEmpty
          name="squareFeet"
        >
          <MenuItem value={"0-1000"}>0-1000</MenuItem>
          <MenuItem value={"1000-2000"}>1000-2000</MenuItem>
          <MenuItem value={"2000-3000"}>2000-3000</MenuItem>
          <MenuItem value={"3000-4000"}>3000-4000</MenuItem>
          <MenuItem value={"4000-5000"}>4000-5000</MenuItem>
          <MenuItem value={"5000+"}>5000+</MenuItem>
          <MenuItem value={"Not sure"}>Not sure</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};

export default SquareFeet;
