import React from "react";
import {
  Grid,
  FormControl,
  Typography,
  InputLabel,
  Select,
  Input,
  MenuItem,
} from "@mui/material";

import { Theme, createStyles } from "@mui/material";

const defaultStyles = (theme: Theme) =>
  createStyles({
    selectLabel: {
      color: "white !important",
      fontSize: theme.typography.subtitle1.fontSize,
    },
    selectInput: {
      marginTop: `${theme.spacing(3)} !important`,
    },
    select: {
      backgroundColor: "white",
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      "&:focus": {
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
      },
    },
  });

const NumberOfBedrooms = (props: any) => {
  const { classes, isDemo, demoForm, form, onChange } = props;

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <Typography variant="subtitle1" align="left" color="primary">
          Number of Bedrooms
        </Typography>
        <Select
          classes={{
            select: classes.select,
          }}
          disabled={isDemo}
          value={isDemo ? demoForm.numberBedrooms : form.numberBedrooms || ""}
          input={
            <Input
              onChange={onChange}
              className={classes.selectInput}
              name="numberBedrooms"
              id="numberBedrooms"
              disableUnderline
            />
          }
          displayEmpty
          name="numberBedrooms"
        >
          <MenuItem value={"1"}>1</MenuItem>
          <MenuItem value={"2"}>2</MenuItem>
          <MenuItem value={"3"}>3</MenuItem>
          <MenuItem value={"4"}>4</MenuItem>
          <MenuItem value={"5"}>5</MenuItem>
          <MenuItem value={"5+"}>5+</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};

export default NumberOfBedrooms;
