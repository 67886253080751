import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import TagManager from "react-gtm-module";

import { store } from "./redux/configure-store";
import "./index.css";
import App from "./App";
import ErrorNotFound from "./common/error-not-found";
import { history } from "./redux/configure-store";

const tagManagerArgs = {
  gtmId: "GTM-N5HCWHV",
  dataLayerName: "KlevrLandingPages",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            exact
            path="/lp/:templateId/:landingPageId/:stepString"
            component={App}
          />
          <Route exact path="/:confirmationCode" component={App} />
          <Route component={ErrorNotFound} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
