import api from "../api/api";

export const INITIALIZE_BUYER_FORM = "INITIALIZE_BUYER_FORM";
export const BUYER_FORM_UPDATED = "BUYER_FORM_UPDATED";
export const BUYER_STEP_UPDATED = "BUYER_STEP_UPDATED";
export const BUYER_CREATING = "BUYER_CREATING";
export const BUYER_LEAD_CREATING = "BUYER_LEAD_CREATING";
export const BUYER_LEAD_CREATING_SUCCESS = "BUYER_LEAD_CREATING_SUCCESS";
export const BUYER_LEAD_CREATING_FAIL = "BUYER_LEAD_CREATING_FAIL";
export const BUYER_LEAD_UPDATING = "BUYER_LEAD_UPDATING";
export const BUYER_LEAD_UPDATING_SUCCESS = "BUYER_LEAD_UPDATING_SUCCESS";
export const BUYER_LEAD_UPDATING_FAIL = "BUYER_LEAD_UPDATING_FAIL";

export const updateLeadBuyer = (leadForm: any) => (dispatch: Function) => {
  dispatch({
    type: BUYER_LEAD_UPDATING,
  });

  return api.leads
    .updateOne(leadForm)
    .then((lead: any) => {
      dispatch({
        type: BUYER_LEAD_UPDATING_SUCCESS,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: BUYER_LEAD_UPDATING_FAIL,
      });
    });
};

export const createLeadBuyer = (leadForm: any) => (dispatch: Function) => {
  dispatch({
    type: BUYER_LEAD_CREATING,
  });

  return api.leads
    .createOne(leadForm)
    .then((lead: any) => {
      dispatch({
        type: BUYER_LEAD_CREATING_SUCCESS,
        payload: { lead },
      });
    })
    .catch((err: any) => {
      dispatch({
        type: BUYER_LEAD_CREATING_FAIL,
      });
    });
};

export const updateForm = (form: any) => (dispatch: Function) => {
  dispatch({
    type: BUYER_FORM_UPDATED,
    payload: { form },
  });
};

export const updateStep = (step: any) => (dispatch: Function) => {
  dispatch({
    type: BUYER_STEP_UPDATED,
    payload: { step },
  });
};
