import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { sellerReducer } from "./seller-reducer";
import { buyerReducer } from "./buyer-reducer";
import { landingsReducer } from "./landings-reducer";

export const createRootReducer = (history: any) =>
  combineReducers({
    buyer: buyerReducer,
    seller: sellerReducer,
    landings: landingsReducer,
    router: connectRouter(history),
  } as any);
