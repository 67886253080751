import React from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import { Typography } from "@mui/material";
// import withWidth from "@mui/material/withWidth";
import { colors } from "../../common/colors";
import Hidden from "@mui/material/Hidden";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 76,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: colors.darkBlue,
    },
    link: {
      color: colors.darkBlue,
      textDecoration: "none",
      opacity: 0.6,
      fontSize: "10px",
    },
    mobileContainer: {
      // height: 76,
      paddingBottom: theme.spacing(),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
    },
  });

interface Props extends WithStyles<typeof styles> {
  landing: any;
  isDemo: boolean;
}

class LandingPagesFooter extends React.Component<Props> {
  override render() {
    const { classes, landing, isDemo } = this.props;

    return (
      <div>
        <Hidden mdDown>
          <Typography className={classes.root}>
            <span className={classes.link}>
              License ID:
              {isDemo ? " demo" : " " + landing.settings.license}
            </span>
            &nbsp;|&nbsp;
            <span className={classes.link}>
              Copyright 2020 - All Rights Reserved
            </span>
            {/* &nbsp;|&nbsp;
            <a
              className={classes.link}
              target="_blank"
              href="https://www.klevrleads.com"
            >
              Website by klevrleads.com
            </a> */}
            &nbsp;|&nbsp;
            <a
              target="_blank"
              className={classes.link}
              href="https://www.klevrleads.com/privacy-policy"
            >
              Privacy Policy
            </a>
            &nbsp;|&nbsp;
            <a
              target="_blank"
              className={classes.link}
              href="https://www.klevrleads.com/terms-of-use"
            >
              Terms
            </a>
          </Typography>
        </Hidden>

        <Hidden mdUp>
          <div className={classes.mobileContainer}>
            <Typography className={classes.link}>
              License ID: {isDemo ? " demo" : " " + landing.settings.license}
            </Typography>
            <Typography className={classes.link}>
              Copyright 2020 - All Rights Reserved
            </Typography>
            <Typography>
              {/* <a
                target="_blank"
                className={classes.link}
                href="https://www.klevrleads.com"
              >
                Website by klevrleads.com
              </a>
              <span className={classes.link}> &nbsp;|&nbsp;</span> */}
              <a
                target="_blank"
                className={classes.link}
                href="https://www.klevrleads.com/privacy-policy"
              >
                Privacy Policy
              </a>
              <span className={classes.link}> &nbsp;|&nbsp;</span>
              <a
                target="_blank"
                className={classes.link}
                href="https://www.klevrleads.com/terms-of-use"
              >
                Terms
              </a>
            </Typography>
          </div>
        </Hidden>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  landing: state.landings.landing,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(LandingPagesFooter)
);
