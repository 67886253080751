import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import {
  Typography,
  Grid,
  Button,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import SecurityIcon from "@mui/icons-material/Security";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";

import { colors } from "../common/colors";
import klevrImage from "../common/logos/klevr-logo-fox-only.svg";
import klevrTestimonialF from "../common/avatars/klevrTestimonialF.jpg";
import { updateForm, updateStep } from "../redux/seller-actions";
import LandingPagesFooter from "./common/landing-pages-footer";
import TagManager from "react-gtm-module";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      backgroundColor: "white",
    },
    listItemGutter: {
      padding: `0px ${theme.spacing()} 0px 0px`,
    },
    userInfo: {
      color: colors.black,
    },
    userlogo: {
      height: "100%",
      [theme.breakpoints.down("md")]: {
        height: "75%",
      },
    },
    userAvatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
    section1: {
      margin: theme.spacing(4),
    },
    primaryText: {
      marginTop: "10vh",
      color: "black",
      fontWeight: "bold",
      textAlign: "center",
    },
    description: {
      color: "#00d88e",
      textAlign: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    lockIcon: {
      fontSize: 14,
      marginRight: theme.spacing(0.5),
    },
    containedButton: {
      width: 240,
      color: "white",
      // backgroundColor: "#da4526",
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(),
      // "&:hover": {
      //   backgroundColor: "#c53d20",
      // },
    },
    spacer: {
      height: "20vh",
    },
  });

interface Props extends WithStyles<typeof styles> {
  t: any;
  isDemo: any;
  landing: any;
  form: any;
}

class LandingSuccess extends React.Component<Props> {
  override componentDidMount() {
    const { form } = this.props;
    const tagManagerArgs = {
      gtmId: "GTM-N5HCWHV",
      events: {
        event: "fullLead",
      },
      dataLayer: {
        fullLead: true,
        userId: form.userId,
        leadId: form.id,
        landingPageId: form.fromLandingPageId,
        tags: form.tags,
        bedrooms: form.numberBedrooms,
        bathrooms: form.numberBathrooms,
        timeFrame: form.timeframe,
      },
      dataLayerName: "KlevrLandingPages",
    };

    TagManager.initialize(tagManagerArgs);
  }

  override render() {
    const { classes, isDemo, landing } = this.props;

    return (
      <div className={classes.root}>
        <Container maxWidth="md">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <List>
                <ListItem
                  classes={{
                    gutters: classes.listItemGutter,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={
                        isDemo
                          ? "KlevrLeads"
                          : `${landing.settings.userFirstName} ${landing.settings.userLastName}`
                      }
                      src={
                        isDemo
                          ? klevrTestimonialF
                          : landing.settings.userProfileImage
                      }
                      className={classes.userAvatar}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.userInfo}
                    primary={
                      isDemo
                        ? "Klevr Leads"
                        : `${landing.settings.userFirstName} ${landing.settings.userLastName}`
                    }
                    secondary={
                      isDemo
                        ? "Klevr Realty"
                        : `${landing.settings.userCompanyName}`
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <div
                style={{
                  height: "56px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  className={classes.userlogo}
                  src={isDemo ? klevrImage : landing.settings.userLogoImage}
                />
              </div>
            </Grid>
          </Grid>
          <div className={classes.section1}></div>
          <Typography variant="h5" className={classes.primaryText}>
            We've received your information and someone will be reaching out
            shortly to speak with you! We look forward to helping you find the
            next home of your dreams!
          </Typography>
          <Button
            className={classes.containedButton}
            variant="contained"
            color="secondary"
            href={`tel:${isDemo ? "" : landing.settings.appPhoneNumber}`}
          >
            Give Us A Call!
          </Button>
          <Typography variant="subtitle2" color="primary">
            Talk to someone Right now! 100% FREE. No obligation.
          </Typography>
          <div className={classes.spacer} />
          <LandingPagesFooter />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isDemo: state.landings.isDemo,
  landing: state.landings.landing,
  form: state.buyer.form,
});

export default connect(mapStateToProps, { updateForm, updateStep })(
  withStyles(styles, { withTheme: true })(LandingSuccess)
);
