import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";
import { Typography, Grid, Button } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import TagManager from "react-gtm-module";

import { updateForm, updateStep } from "../redux/seller-actions";
import MapWithMarker from "./common/map-with-marker";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    primaryText: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center",
    },
    secondaryText: {
      color: "white",
      opacity: 0.5,
      fontWeight: 500,
      marginTop: theme.spacing(2),
      textAlign: "center",
    },
    whiteText: {
      color: "white",
      textAlign: "center",
      marginLeft: "50px",
      marginRight: "50px",
    },
    greyText: {
      color: "rgba(255, 255, 255, 0.5)",
      textAlign: "center",
    },
    orangeText: {
      color: "#FFB107",
      textAlign: "center",
    },
    content: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    map: {
      //height: 300,
      backgroundColor: theme.palette.grey[400],
      borderRadius: theme.spacing(0.5),
    },
    description: {
      color: "#00d88e",
      textAlign: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    lockIcon: {
      fontSize: 14,
      marginRight: theme.spacing(0.5),
    },
    footerText: {
      color: "rgba(255, 255, 255, 0.5)",
      textAlign: "center",
    },
    link: {
      color: "#00d88e",
    },
    owners: {
      backgroundColor: "#2E3B67",
      borderRadius: theme.spacing(0.5),
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
    },
    estimatedValues: {
      backgroundColor: "#00d88e",
      borderRadius: theme.spacing(0.5),
      lineHeight: 1,
      marginLeft: -theme.spacing(),
      marginRight: -theme.spacing(),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    ownerName: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    contact: {
      backgroundColor: "#2E3B67",
      borderRadius: theme.spacing(0.5),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    horizontalCenter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "10px",
    },
    avatar: {
      width: 64,
      height: 64,
      borderRadius: 32,
      backgroundColor: theme.palette.grey[400],
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(),
    },
    containedButton: {
      width: 240,
      color: "white",
      backgroundColor: "#da4526",
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: "#c53d20",
      },
    },
    securedBy: {
      fontSize: 12,
      color: "white",
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    securityIcon: {
      fontSize: 12,
    },
    securedOrg: {
      fontWeight: "bold",
      color: "white",
    },
    checkCircleIcon: {
      fontSize: 32,
      color: "#00d88e",
      marginBottom: theme.spacing(),
    },
  });

interface Props extends WithStyles<typeof styles> {
  t: any;
}

class LandingSuccess extends React.Component<any> {
  override componentDidMount() {
    const { form } = this.props;
    const tagManagerArgs = {
      gtmId: "GTM-N5HCWHV",
      events: {
        event: "fullLead",
      },
      dataLayer: {
        fullLead: true,
        userId: form.userId,
        leadId: form.id,
        landingPageId: form.fromLandingPageId,
        zipCode: form.zipcodeAddress,
        city: form.cityAddress,
        state: form.stateAddress,
        tags: form.tags,
        propertyEstimate: form.zestimate,
        bedrooms: form.numberBedrooms,
        bathrooms: form.numberBathrooms,
        squareFeet: form.squareFeet,
        timeFrame: form.timeframe,
      },
      dataLayerName: "KlevrLandingPages",
    };

    TagManager.initialize(tagManagerArgs);
  }

  override render() {
    const { classes, form, demoForm, isDemo, landing, errors } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h5" className={classes.primaryText}>
          {`Beautiful home ${
            isDemo ? demoForm.name.split(" ")[0] : form.name.split(" ")[0]
          }!`}
        </Typography>
        <Typography variant="subtitle1" className={classes.secondaryText}>
          {isDemo ? demoForm.formatted_address : form.formatted_address}
        </Typography>
        <Grid className={classes.content} container justifyContent="center">
          <Grid item xs={12} md={8}>
            <div className={classes.map}>
              <MapWithMarker
                height="40vh"
                markerLat={isDemo ? demoForm.lat : form.lat}
                markerLng={isDemo ? demoForm.lng : form.lng}
              />
            </div>
            <Button
              className={classes.containedButton}
              variant={"contained"}
              href={`tel:${isDemo ? "" : landing.settings.appPhoneNumber}`}
            >
              Give Us A Call!
            </Button>
            <Typography className={classes.description}>
              <LockIcon className={classes.lockIcon} />
              100% FREE. No obligation. Your info is secure and will not be
              shared.
            </Typography>

            <div className={classes.owners}>
              {isDemo || form.zestimate ? (
                <Typography
                  variant="h6"
                  className={classNames(
                    classes.estimatedValues,
                    classes.whiteText
                  )}
                >
                  ${isDemo ? demoForm.zestimate : form.zestimate}
                  <Typography className={classes.whiteText}>
                    Estimated value
                  </Typography>
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  className={classNames(
                    classes.estimatedValues,
                    classes.whiteText
                  )}
                >
                  Sorry, we couldn't find an estimation for your property in our
                  database.
                </Typography>
              )}
            </div>

            <div className={classes.contact}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Typography className={classes.whiteText}>
                    <span className={classes.orangeText}>
                      Unhappy with your valuation?
                    </span>
                  </Typography>
                  <Typography className={classes.whiteText}>
                    Contact us, we'll work with you to create a more accurate
                    valuation.
                  </Typography>
                </Grid>
                <Grid className={classes.horizontalCenter} item xs={4}>
                  <CheckCircleIcon className={classes.checkCircleIcon} />
                  <Typography className={classes.greyText}>
                    200+ Sales made
                  </Typography>
                </Grid>
                <Grid className={classes.horizontalCenter} item xs={4}>
                  <CheckCircleIcon className={classes.checkCircleIcon} />
                  <Typography className={classes.greyText}>
                    98% client satisfaction
                  </Typography>
                </Grid>
                <Grid className={classes.horizontalCenter} item xs={4}>
                  <CheckCircleIcon className={classes.checkCircleIcon} />
                  <Typography className={classes.greyText}>
                    Background check
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Typography className={classes.footerText}>
          Homes are selling in record times!&nbsp;
          <a
            className={classes.link}
            href={`tel:${isDemo ? "" : landing.settings.appPhoneNumber}`}
          >
            Ask us
          </a>
          &nbsp; about getting real-time updates of new listings that are a
          perfect match for you.
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.seller.form,
  demoForm: state.seller.demoForm,
  step: state.seller.step,
  errors: state.seller.errors,
  landing: state.landings.landing,
  landingUser: state.landings.landingUser,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, { updateForm, updateStep })(
  withStyles(styles, { withTheme: true })(LandingSuccess)
);
