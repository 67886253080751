import React from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import {
  Typography,
  Grid,
  Button,
  Input,
  Container,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  FormHelperText,
  Divider,
} from "@mui/material";

import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import HouseIcon from "@mui/icons-material/House";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "../../theme/react-phone-input-2.css";
import SearchIcon from "@mui/icons-material/Search";
import TagManager from "react-gtm-module";
import LandingPagesFooter from "../common/landing-pages-footer";

import ReviewCard from "../../common/review";
import { formatPhoneNumberIntoE164 } from "../../common/utils";
import {
  updateForm,
  updateStep,
  createLeadBuyer,
  updateLeadBuyer,
} from "../../redux/buyer-actions";
import isEmail from "validator/lib/isEmail";
import { colors } from "../../common/colors";
import MortgageRates from "../../common/background-images/Mortgage-Rates.jpg";
import klevrImage from "../../common/logos/klevr-logo-fox-only.svg";
import Loading from "../../common/loading";
import klevrTestimonialF from "../../common/avatars/klevrTestimonialF.jpg";
import klevrTestimonialM from "../../common/avatars/klevrTestimonialM.jpg";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      backgroundColor: "white",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "0px",
        paddingTop: theme.spacing(2),
      },
    },
    section1: {
      margin: theme.spacing(4),
    },
    dividerRoot: {
      backgroundColor: colors.black,
      margin: `${theme.spacing(2)} 0px ${theme.spacing(4)}`,
    },
    divider2Root: {
      backgroundColor: colors.black,
      margin: `${theme.spacing(2)} 0px`,
    },
    userInfo: {
      color: colors.black,
    },
    leftDivImage: {
      marginTop: "5px",
      height: "300px",
      width: "100%",
      paddingTop: "10px",
      backgroundImage: `url(${MortgageRates})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      [theme.breakpoints.down("md")]: {
        height: "200px",
        marginBottom: theme.spacing(2),
        paddingTop: "0px",
      },
    },
    inputContainer: {
      [theme.breakpoints.down("md")]: {
        marginLeft: "0px",
      },
    },
    userlogo: {
      height: "100%",
      [theme.breakpoints.down("md")]: {
        height: "75%",
      },
    },
    mainHeader: {
      color: colors.red,
    },
    subHeader: {
      color: colors.black,
    },
    listItemGutter: {
      padding: `0px ${theme.spacing()} 0px 0px`,
    },
    userAvatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
    input: {
      // marginTop: `${theme.spacing(3)} !important`,
      backgroundColor: colors.lightGrey,
      borderRadius: theme.spacing(0.5),
      padding: `${theme.spacing()} ${theme.spacing(2)}`,
      "&:focus": {
        backgroundColor: colors.lightGrey,
        borderRadius: theme.spacing(0.5),
      },
    },
    selectLabel: {
      color: "black !important",
      fontSize: theme.typography.subtitle1.fontSize,
    },
    containedButton: {
      backgroundColor: colors.klevrGreen,
      marginTop: theme.spacing(2),
      "&:hover": {
        backgroundColor: colors.klevrGreenDark,
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  //
}

class LandingStep1 extends React.Component<any> {
  override componentDidMount() {
    const { form, landing, updateForm, isDemo } = this.props;
    let newForm = {
      ...form,
      userId: landing.userId,
      fromLandingPageId: landing.id,
      tags: ["buyer"],
      email: form.email || "",
      numberBedrooms: "",
      numberBathrooms: "",
      timeframe: "",
      name: "",
      phoneNumber: "",
    };
    updateForm(newForm);

    if (isDemo === true) {
      return;
    } else {
      const tagManagerArgs = {
        gtmId: "GTM-N5HCWHV",
        events: {
          event: "initialPageView",
        },
        dataLayer: {
          initialPageView: true,
          userId: landing.userId,
          landingPageId: landing.id,
          tags: landing.settings.templateType,
        },
        dataLayerName: "KlevrLandingPages",
      };

      TagManager.initialize(tagManagerArgs);
    }
  }

  handleChange = (e: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    updateForm(newForm);
    console.log("newForm", newForm);
  };

  setPhoneNumber = (value: any, country: any, e: any, formattedValue: any) => {
    const { form, updateForm } = this.props;
    let newForm = {
      ...form,
      phoneNumber: "+" + value,
    };

    updateForm(newForm);
  };

  handleClick = (e: any) => {
    const { form, isDemo, updateStep, createLeadBuyer, updateLeadBuyer } =
      this.props;

    if (isDemo) {
      updateStep("success");
      return;
    }

    let phoneNumber = formatPhoneNumberIntoE164(form.phoneNumber);

    if (phoneNumber && form.name.length > 0) {
      let newForm = {
        ...form,
        phoneNumber,
      };
      if (isEmail(form.email)) {
        if (!form.id) {
          createLeadBuyer(newForm).then(() => {
            updateStep("success");
          });
        } else {
          updateLeadBuyer(form).then(() => {
            updateStep("success");
          });
        }
      }
    }
  };

  override render() {
    const { classes, form, demoForm, landing, isFetching, isDemo } = this.props;

    return (
      <div className={classes.root}>
        <Container maxWidth="md">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <List>
                <ListItem
                  classes={{
                    gutters: classes.listItemGutter,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={
                        isDemo
                          ? "KlevrLeads"
                          : `${landing.settings.userFirstName} ${landing.settings.userLastName}`
                      }
                      src={
                        isDemo
                          ? klevrTestimonialF
                          : landing.settings.userProfileImage
                      }
                      className={classes.userAvatar}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.userInfo}
                    primary={
                      isDemo
                        ? "Klevr Leads"
                        : `${landing.settings.userFirstName} ${landing.settings.userLastName}`
                    }
                    secondary={
                      isDemo
                        ? "Klevr Realty"
                        : `${landing.settings.userCompanyName}`
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <div
                style={{
                  height: "56px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  className={classes.userlogo}
                  src={isDemo ? klevrImage : landing.settings.userLogoImage}
                />
              </div>
            </Grid>
          </Grid>
          <div className={classes.section1}></div>
          <Typography variant="h3" gutterBottom className={classes.mainHeader}>
            {landing && landing.settings && landing.settings.heading
              ? landing.settings.heading
              : "Mortgage Rates are at Historic Lows!"}
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.subHeader}>
            {landing && landing.settings && landing.settings.subheading
              ? landing.settings.subheading
              : "Lock in your rate today and get the home of your dreams!"}
          </Typography>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Divider classes={{ root: classes.divider2Root }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.leftDivImage} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              spacing={2}
              container
              justifyContent="center"
              alignItems="flex-start"
              className={classes.inputContainer}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="primary">
                    Name
                  </Typography>
                  <Input
                    placeholder="Your name"
                    className={classes.input}
                    name="name"
                    id="name"
                    disableUnderline
                    disabled={isDemo}
                    value={isDemo ? demoForm.name : form.name || ""}
                    onChange={this.handleChange}
                  />
                  {/* {errors.name && (
                    <FormHelperText>{errors.name}</FormHelperText>
                  )} */}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" align="left" color="primary">
                  Phone
                </Typography>
                <PhoneInput
                  inputProps={{
                    name: "phoneNumber",
                    label: "phoneNumber",
                    // required: true,
                  }}
                  inputStyle={{
                    background: "rgba(0, 0, 0, 0.09)",
                    width: "100%",
                    borderRadius: "4px",
                    height: "48px",
                  }}
                  // specialLabel={"Phone Number"}
                  country={"us"} // initialcountry (in some cases behaves weirdly)
                  onlyCountries={["au", "ca", "it", "mx", "us", "es"]}
                  countryCodeEditable={false}
                  disableDropdown={true}
                  placeholder={"For verification purposes only"}
                  disabled={isDemo}
                  value={isDemo ? demoForm.phoneNumber : form.phoneNumber || ""}
                  onChange={(value, country, e, formattedValue) =>
                    this.setPhoneNumber(value, country, e, formattedValue)
                  }
                />
                {/* {!!errors.phoneNumber && (
                  <FormHelperText error={true}>
                    {errors.phoneNumber}
                  </FormHelperText>
                )} */}
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" align="left" color="primary">
                    Email
                  </Typography>
                  <Input
                    placeholder="Your email address"
                    className={classes.input}
                    name="email"
                    id="email"
                    disableUnderline
                    value={isDemo ? demoForm.email : form.email || ""}
                    onChange={this.handleChange}
                  />
                  {/* {errors.email && (
                    <FormHelperText>{errors.email}</FormHelperText>
                  )} */}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  // className={classes.containedButton}
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth
                  disabled={false}
                  onClick={this.handleClick}
                >
                  Contact Us!
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Divider classes={{ root: classes.divider2Root }} />
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              wrap="wrap"
              spacing={2}
            >
              <Grid item xs={12} sm>
                <LockOpenIcon style={{ fontSize: "50px", color: "black" }} />
                <Typography
                  variant="h6"
                  style={{ color: "black" }}
                  gutterBottom
                >
                  Lock In Your Rate
                </Typography>
                <Typography color="textPrimary">
                  Rates are at unprecidented lows, lock in your mortgage rate
                  today and get the house of your dreams!
                </Typography>
              </Grid>
              <Grid item xs={12} sm>
                <AccessAlarmIcon style={{ fontSize: "50px", color: "black" }} />
                <Typography
                  variant="h6"
                  style={{ color: "black" }}
                  gutterBottom
                >
                  Time is running out
                </Typography>
                <Typography color="textPrimary">
                  Interest rates this low won't last forever. Take advantage of
                  this opportunity while you still can.
                </Typography>
              </Grid>
              <Grid item xs={12} sm>
                <HouseIcon style={{ fontSize: "50px", color: "black" }} />
                <Typography
                  variant="h6"
                  style={{ color: "black" }}
                  gutterBottom
                >
                  Little to NO down payment!
                </Typography>
                <Typography color="textPrimary">
                  Even homebuyers with "average" credit are getting homes with
                  little to NO down payment!!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <LandingPagesFooter />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  form: state.buyer.form,
  demoForm: state.buyer.demoForm,
  step: state.buyer.step,
  isFetching: state.buyer.isFetching,
  landing: state.landings.landing,
  isDemo: state.landings.isDemo,
});

export default connect(mapStateToProps, {
  updateForm,
  updateStep,
  createLeadBuyer,
  updateLeadBuyer,
})(withStyles(styles, { withTheme: true })(LandingStep1));
