import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createStyles } from "@mui/material";

import {
  Typography,
  LinearProgress,
  Hidden,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import { colors } from "../../common/colors";
import klevrLogoDark from "../../common/logos/KLEVRHomeValuesDark.png";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // height: 76,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing(0.5),
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        flexDirection: "column",
      },
    },
    primaryText: {
      color: colors.darkBlue,
    },
    secondaryText: {
      fontSize: "12px",
    },
    headerDiv: {
      textAlign: "left",
    },
    logo: {
      display: "flex",
      //alignItems: "center"
    },
    logoText: {
      marginLeft: theme.spacing(0.5),
    },
    numSteps: {
      display: "inline-block",
      width: 160,
    },
    progressRoot: {
      marginTop: theme.spacing(0.5),
      borderRadius: 2,
      backgroundColor: colors.lightGrey,
    },
    progressActive: {
      borderRadius: 2,
      backgroundColor: "#2694F1",
    },
    //
    listItemGutter: {
      padding: `0px ${theme.spacing()} 0px 0px`,
    },
    userInfo: {
      color: colors.black,
    },
    userlogo: {
      height: "100%",
      [theme.breakpoints.down("md")]: {
        height: "75%",
      },
    },
    userAvatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  location: any;
  step: string;
  isDemo: any;
  landing: any;
  settings: any;
  landingUser: any;
}

class LandingPagesHeader extends React.Component<Props> {
  renderProgress() {
    const {
      classes,
      location: { pathname, hash },
      step,
    } = this.props;

    //if (pathname === "/") {
    let label: string = "";
    let progress: number = 0;
    if (step === "2") {
      label = "Just One More Step!";
      progress = 50;
    } else if (step === "3") {
      label = "Complete!";
      progress = 100;
    } else if (step === "4") {
      label = "CMA Analysis";
      progress = 50;
    } else if (step === "5") {
      label = "Last Step!";
      progress = 75;
    } else {
      label = "Real Home Estimates Without the Hassle";
    }

    if (progress > 0) {
      return (
        <div>
          <Typography className={classes.primaryText}>
            <span className={classes.numSteps}>{label}</span>
          </Typography>
          <Typography>{progress}%</Typography>
          <LinearProgress
            variant="determinate"
            classes={{
              root: classes.progressRoot,
              bar: classes.progressActive,
            }}
            value={progress}
          />
        </div>
      );
    }

    return (
      <Typography
        className={classNames(classes.primaryText, classes.secondaryText)}
      >
        {label}
      </Typography>
    );
  }

  override render() {
    const { classes, landing, isDemo, landingUser, settings } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.logo}>
          {isDemo ? (
            <Typography className={classes.headerDiv} variant="h6">
              <img
                src={klevrLogoDark}
                alt="Klevr Leads"
                style={{ verticalAlign: "middle" }}
                width="100%"
                height="100%"
              />
            </Typography>
          ) : (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <List>
                  <ListItem
                    classes={{
                      gutters: classes.listItemGutter,
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={
                          landingUser.firstName && landingUser.lastName
                            ? `${landingUser.firstName} ${landingUser.lastName}`
                            : "KlevrLeads"
                        }
                        src={landingUser.profileImageUrl || null}
                        className={classes.userAvatar}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      className={classes.userInfo}
                      primary={
                        landingUser.firstName && landingUser.lastName
                          ? `${landingUser.firstName} ${landingUser.lastName}`
                          : "KlevrLeads"
                      }
                      secondary={
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="caption">
                            {landingUser.companyName || "Klevr Realty"}
                          </Typography>
                          <Typography variant="caption">
                            {settings.userLicense
                              ? `License: ${settings.userLicense}`
                              : "License: Demo"}
                          </Typography>
                        </div>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item>
                <div
                  style={{
                    height: "56px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    className={classes.userlogo}
                    src={landing.settings.logoImageUrl}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </div>
        {/* <Hidden smDown>{this.renderProgress()}</Hidden> */}
        {this.renderProgress()}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isDemo: state.landings.isDemo,
  landing: state.landings.landing,
  settings: state.landings.landing.settings,
  landingUser: state.landings.landingUser,
  step: state.seller.step,
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(LandingPagesHeader)
);
